import React, { Component, createRef } from 'react';
import { Modal, Input, Button, Dropdown, Menu, Icon, Form, Typography } from 'antd';
import { SmartRequests } from '../../../utilities';
import msg from './messages.js';
import { injectIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
class PhoneModalValidation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            guestPhone: this.props.guestPhone,
            guestName: this.props.guestName,
            guestUnit: this.props.guestUnit,
            client_secret: this.props.client_secret,
            isLoading: false,
            errorMessage: '',
            verificationCode: '',
        };
        this.inputRefs = Array.from({ length: 4 }, () => createRef());
    }

    generate_uuid_secret = () => {
        return uuidv4();
    }
    handleSendSMS = () => {
        const { guestPhone } = this.state;
        if (!guestPhone || !isValidPhoneNumber(guestPhone)) {
            this.setState({ errorMessage: 'Please enter a valid phone number.' });
            return;
        }
        if (!this.state.guestName) {
            this.setState({ errorMessage: 'The name is required.' });
            return;
        }
        const uuid_secret = this.generate_uuid_secret();
        this.setState({ isLoading: true, errorMessage: '' , client_secret: uuid_secret, 
            guestPhone: guestPhone, 
        });
        SmartRequests.post(`/${this.props.buildingName}/resident/guest/ws/2/guest_sms/${this.props.pageId}/guest_creation`, 
                { guestPhone: guestPhone, client_secret: uuid_secret,
                  guestName: this.state.guestName, guestUnit: this.state.guestUnit }
        ).then(resp => {
            if (resp.status === 200){
                this.props.onSmsSent(guestPhone, uuid_secret, this.state.guestName, this.state.guestUnit);
            }else{
                this.setState({ errorMessage: resp.data.error_msg });
            }
        }).catch((e)=>{
            this.setState({ errorMessage: e.response.data.error_msg });
        }).finally(()=>{
            this.setState({ isLoading: false });
        })
    };

    resendCode = () => {
        // Prevent resend if timer is still running
        if (this.state.resendTimer > 0) return;
        if (this.state.disableUntilResend) this.setState({ disableUntilResend: false });
        if (this.state.errorMessageValidating) this.setState({ errorMessageValidating: '' });
        // Send SMS
        this.handleSendSMS();
    
        // Set initial timer
        this.setState({ resendTimer: 60 });
    
        // Start countdown
        const interval = setInterval(() => {
            this.setState(prevState => {
                const newTimer = prevState.resendTimer - 1;
                
                // Clear interval when timer reaches 0
                if (newTimer === 0) {
                    clearInterval(interval);
                }
                
                return { resendTimer: newTimer };
            });
        }, 1000);
    
        // Store interval ID to clear on component unmount
        this.countdownInterval = interval;
    }

    componentWillUnmount() {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }
    }
    
    validateStep1(translate){
        const { guestPhone, isLoading, errorMessage, guestName, guestUnit } = this.state;
        return(
            <>
                <div>
                    <Typography.Title level={3} >
                        {translate(msg.guestFormTitle)}
                    </Typography.Title>
                    
                    <Form layout="vertical">
                        <Form.Item
                            label={translate(msg.namePlaceholder)}
                            required
                            style={{ marginTop: '1rem' , marginBottom: '4px' }}
                        >
                        <Input
                            value={guestName}
                            onChange={(e) => this.setState({ guestName: e.target.value })}
                            placeholder={translate(msg.namePlaceholder)}
                            size="large"
                        />
                        </Form.Item>

                        <Form.Item
                            label={translate(msg.unitPlaceholder)}
                            help={translate(msg.unitInfo)}
                        >
                            <Input 
                                value={guestUnit}
                                onChange={(e) => this.setState({ guestUnit: e.target.value })}
                                placeholder={translate(msg.unitPlaceholder)}
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item
                            label={translate(msg.phoneNumberPlaceholder)}
                            required
                            help={translate(msg.phoneValidationExplanation)}
                        >
                            <PhoneInput
                                value={guestPhone}
                                defaultCountry={this.props.country}
                                onChange={phoneNumber => this.setState({ guestPhone: phoneNumber })}
                                placeholder="Enter phone number"
                                />
                        </Form.Item>
                    </Form>
                </div>
                {errorMessage && <div style={{ color: 'red', marginBottom: '1rem' }}>{errorMessage}</div>}
                <Button
                type="primary"
                block
                onClick={this.handleSendSMS}
                loading={isLoading}
                >
                {translate(msg.sendSms)}
                </Button>
            </>
        )
    }

    handleInputChange = (value, index) => {
        if (/^\d*$/.test(value)) {
            const newCode = this.state.verificationCode.split('');
            newCode[index] = value;
            const newCodeStr = newCode.join('');
            this.setState({ verificationCode: newCodeStr });
            if (index < this.inputRefs.length - 1) {
                this.inputRefs[index + 1].current.focus();
            }
        }
    }

    handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !this.state.verificationCode[index] && index > 0) {
          // Move to the previous input if Backspace is pressed and the current input is empty
          this.inputRefs[index - 1].current.focus();
        }else if(e.key === 'Enter' && index === this.state.verificationCode.length-1){
            this.handleVerifyCode();
        }
    };

    retryWithNewPhone = () => {
        this.setState({guestPhone: this.props.guestPhone, guestName: this.props.guestName, disableUntilResend: false,
            guestUnit: this.props.guestUnit, verificationCode: '', errorMessage: '', errorMessageValidating: ''})
        this.props.retryWithNewPhone()
    }

    validateStep2(translate){
        const { verificationCode, isLoadingValidating, errorMessageValidating, successMessage, disableUntilResend} = this.state;
        return (
            <>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '4rem' }}>
                    <h4 style={{ marginBottom: '4rem' }}>
                        {translate(msg.verificationCodeTitle)}
                    </h4>
                    <div style={{ 
                        display: 'flex', 
                        justifyContent: 'center',
                        width: 'fit-content'
                    }}>
                        {[0, 1, 2, 3].map((index) => (
                            <Input
                                key={index}
                                maxLength={1}
                                ref={this.inputRefs[index]}
                                style={{ 
                                    width: '40px', 
                                    textAlign: 'center',
                                    marginLeft: index > 0 ? '2rem' : '0' // Add margin to all except first input
                                }}
                                value={verificationCode[index] || ''}
                                onChange={(e) => this.handleInputChange(e.target.value, index)}
                                onKeyDown={(e) => this.handleKeyDown(e, index)}
                            />
                        ))}
                    </div>
                </div>
                {successMessage && <div style={{ color: 'green', marginBottom: '1rem', textAlign: 'center' }}>{successMessage}</div>}
                {errorMessageValidating && <div style={{ color: 'red', marginBottom: '1rem', textAlign: 'center' }}>{errorMessageValidating}</div>}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={this.props.onCancel}
                    style={{ marginRight: '12px' }}
                    >{translate(msg.cancel)}</Button>
                    <Button
                        type="primary"
                        onClick={this.handleVerifyCode}
                        loading={isLoadingValidating}
                        disabled={verificationCode.length != 4 || disableUntilResend}
                    >
                        {translate(msg.validateCode)}
                    </Button>
                </div>
            </>
        );
    }
    handleVerifyCode = (e, newCodeStr=null) => {
        let { verificationCode } = this.state;
        if(newCodeStr){// added newCodeStr bcause setState is async, auto verify is too fast
            verificationCode = newCodeStr;
        }
        this.setState({ isLoadingValidating: true, errorMessageValidating: '' });
        SmartRequests.put(`/${this.props.buildingName}/resident/guest/ws/2/guest_sms/${this.props.pageId}/validate_sms`, 
            { code: verificationCode, client_secret: this.state.client_secret? this.state.client_secret: this.props.client_secret,
                guestName: this.state.guestName, guestUnit: this.state.guestUnit, guestPhone: this.state.guestPhone})
            .then(resp => {
                if (resp.status === 200 && resp.data.valid) {
                    this.setState({ successMessage: 'Your phone number has been verified.', errorMessageValidating: '' });
                    setTimeout(() => {
                        this.props.phoneValidationSucces();
                    }, 2000);
                }else{
                    if (resp.data.disable_button) {
                        this.setState({ errorMessageValidating: resp.data.error_msg, disableUntilResend: true });
                    }else{
                        this.setState({ errorMessageValidating: resp.data.error_msg });
                    }
                }
            })
            .catch((error) => {
                this.setState({ errorMessageValidating: error.response.data.error_msg });
            })
            .finally(() => {
                this.setState({ isLoadingValidating: false });
            });
    };

    render() {
        const { visible, onClose , isMobile} = this.props;
        const translate = this.props.intl.formatMessage 
        return (
        <Modal
            transitionName='ant-modal-slide-up'
            className={'modal phone-validation-modal' + (isMobile ? ' mobile' : '')}
            visible={visible}
            onCancel={onClose}
            closable={false}
            footer={
                this.props.smsSent ?
                    (<div style={{ textAlign: 'center' }}>
                        {translate(msg.noCodeReceived)}
                        <div>
                            <a onClick={this.resendCode} 
                            style={
                                this.state.resendTimer > 0 ?
                                {
                                    color: 'black',
                                    cursor: 'not-allowed',
                                    marginTop: '10px',
                                    border: 'none',
                                    background: 'none',
                                    padding: '4px 8px',
                                }:
                                {
                                    color: '#1890ff',
                                    cursor: 'pointer',
                                    marginTop: '10px',
                                    border: 'none',
                                    background: 'none',
                                    padding: '4px 8px',
                                }
                            }>
                            {this.state.resendTimer > 0 
                                    ? translate(msg.resendCodeIn, { time: this.state.resendTimer })
                                    : translate(msg.resendCode)
                                }
                            </a>
                            {translate(msg.noCodeReceived2)}
                            <a onClick={()=>this.retryWithNewPhone()} style={{ marginLeft: '5px' }}>
                                {translate(msg.retryWithNewPhoneNumber)}
                            </a>
                        </div>
                    </div>) : null
            }
            title={
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div>
                        <h3 style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>
                            {translate(msg.guestNotLoggedInTitle)}
                            <a
                            href="/#/access/login"
                            style={{
                                textDecoration: 'underline',
                                color: 'inherit',
                            }}
                            >
                            {translate(msg.LogInLink)}
                            </a>
                            {translate(msg.guestNotLoggedInTitleContinuation)}
                        </h3>
                    </div>
                    <CloseOutlined 
                    style={{ marginLeft: '1rem' }}
                    onClick={() => this.props.onCancel()} />
                </div>
            }
        >
            {
                !this.props.smsSent?
                this.validateStep1(translate) :
                this.validateStep2(translate)
            }
            
        </Modal>
        );
    }
}

export default injectIntl(PhoneModalValidation);
