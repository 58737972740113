import React from 'react';
import { withRouter } from 'react-router-dom'

import PageUl from '../../../components/PageUl.js'
import Description from '../../PageView/Description';
import { reloadIfNeeded, PaginationControl } from '../../../components/Pagination.js'

import debounce from 'lodash.debounce';
import { Spin } from 'antd'

import { injectIntl } from "react-intl";

import customMsg from '../Custom/messages'
import msg from './messages'

import Header from '../../PageView/Header.js';
import { Radio, Input, Typography } from 'antd'
import PageFrame from '../../../components/PageFrame.js';

const { Search } = Input
const { Title, Text } = Typography;


const OrderCard = ({ dishes, price, state, state_translated, fmt_created_at, defaultLang,
  currentLang, receiveTime, receiveMethod, translate, cancelNote }) => {
  let dishes_by_category = {}
  let categories_default_lang = []
  let categories = []
  const state_to_show = state_translated.charAt(0).toUpperCase() + state_translated.slice(1);
  // CHANGE THIS TO SORT WHEN THE ORDER IS MADE, SO WHEN IT ARRIVES HERE IT IS ALREADY ORGANIZED BY THE MENU CATEGORIES
  dishes.forEach((dish) => {
    if (categories_default_lang.includes(dish.category[defaultLang])) {
      dishes_by_category[dish.category[defaultLang]].push({ dish: dish, quantity: dish.quantity })
    } else {
      dishes_by_category[dish.category[defaultLang]] = [{ dish: dish, quantity: dish.quantity }]
      categories.push(dish.category)
      categories_default_lang.push(dish.category[defaultLang])
    }
  })
  return (
    <li className='li-request'>
      <div className='list-item-request'>
        <div className='header-item'>
          <div className='header-description' style={{ width: '95%' }}>
            <div className='header-title'>
              <div style={{ width: '100%', margin: 'auto', display: 'flex', flexFlow: 'column' }}>
                <Title level={2} className='title' style={{ width: '100%', margin: 'auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  {state_to_show} <div>${price.toFixed(2)}</div>
                </Title>
                <p style={{ fontSize: '1rem' }}>
                  {state == 'delivered' ? (
                    translate(msg.deliveredAt, { dateTime: receiveTime })
                  ) : state != 'cancelled' ? (
                    translate(msg.estimatedReceiveTime, { receiveMethod: receiveMethod, time: receiveTime })
                  ) : state == 'cancelled' && cancelNote ? (
                    translate(msg.cancelledNote, { cancelNote: cancelNote })
                  )
                    :
                    null
                  }
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='content-item'>

          <div className='details-item'>
            <div className='content-details'>
              {categories.map((category) => (
                <div key={category[defaultLang]} style={{ marginRight: '60px', marginLeft: '1vw', borderLeft: 'solid', borderLeftWidth: '1px', paddingLeft: '10px', marginTop: '10px' }}>
                  <Title level={4}>{category[currentLang] || category[defaultLang]}</Title>
                  <ul>
                    {dishes_by_category[category[defaultLang]].map((dish, index) => {
                      const extras_price = dish.dish.selectedExtras.reduce((n, { price }) => n + price, 0) // Calculate price of extras

                      return (
                        <li key={index}>
                          <Text>{dish.quantity} X {dish.dish.name[currentLang] || dish.dish.name[defaultLang]}: ${(dish.quantity * (dish.dish.price + extras_price)).toFixed(2)}</Text>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <p style={{ fontSize: '1rem' }}>
              {translate(msg.createdAt, { fmt_created_at: fmt_created_at })}
            </p>
          </div>
        </div>
      </div>
    </li>
  )
};

class MyOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingData: true,
      subPages: [],
      pageName: '',
      pathMatchesExactly: false,
      searching: false,
      searchResults: null,
      pageOffset: 0,
      dataRequest: null,
    };
    this.requests = []
    this.query = ''
    this.category = 'pending'
    this.pageSize = 10;
    this.info = {};
    this.debouncedReloadIfNeeded = debounce(reloadIfNeeded.bind(this, 
      !this.props.isGuest? 
        `food_order/${this.props.pageId}/orders?` :
        `/${this.props.buildingName}/resident/guest/ws/2/food_order/${this.props.pageId}/${this.props.guestPhone}/orders?client_secret=${this.props.client_secret}&`, 
      this.setState.bind(this)), 300)
  }

  updateOffset = (newOffset) => {
    this.setState({ pageOffset: newOffset })
  }
  setSearchQuery(e) {
    this.query = e.target.value
    this.setState({ pageOffset: 0 });
    this.debouncedReloadIfNeeded()
  }
  setSearchCategory(e) {
    this.category = e.target.value
    if (this.category == 'all') {
      this.category = null
    }
    this.setState({ pageOffset: 0 });
    this.debouncedReloadIfNeeded()
  }

  componentDidMount = () => {
    this.debouncedReloadIfNeeded()
    if(this.props.isGuest){
      this.props.subscribeToGuestChannel(
        `${this.props.reqData.building.id}/guest/${this.props.guestPhone.slice(1)}/service/restaurant`,
        ()=>{
          this.reload= true
          this.debouncedReloadIfNeeded()
        }
      )
    }
  }

  render() {
    if (this.props.data && this.props.data.newMqttMessage && this.props.data.mqttTopicMessage === `restaurant` && this.props.data.mqttMessage.restaurant_id == this.props.pageId) {
      this.reload = true
      this.debouncedReloadIfNeeded()
      this.props.methods.pageMessageReceivedCallback()
    }
    this.orders = this.props.data && this.props.data.myOrders ? this.props.data.myOrders[this.props.pageId] : []
    if (this.state.searchResults) {
      this.orders = this.state.searchResults
    }

    const translate = this.props.intl.formatMessage // Get translation function from props

    return (
      <PageFrame>
        <Header>{translate(msg.myOrdersTitle)}</Header>
        <div className='custom'>
          {this.state.pageText ? <Description text={this.state.pageText} /> : null}
          {this.orders && this.orders.results && (
            <PageUl >
              <div className="filterBar">
                {!this.state.dataRequest &&
                  <Radio.Group
                    defaultValue="pending"
                    buttonStyle="solid"
                    onChange={this.setSearchCategory.bind(this)}
                  >
                    <Radio.Button value="pending">{translate(customMsg.ongoing)}</Radio.Button>
                    <Radio.Button value="all">{translate(customMsg.all)}</Radio.Button>
                  </Radio.Group>
                }
                &nbsp;
                <Search
                  placeholder="title"
                  onChange={this.setSearchQuery.bind(this)}
                  style={{ width: 120 }}
                  allowClear
                />
              </div>

              <Spin spinning={this.orders == null} delay={500}>
                {this.orders.results && this.orders.results.length > 0 ? (
                  <div>
                    <PaginationControl
                      t={this.props.intl.formatMessage}
                      pageOffset={this.state.pageOffset}
                      updateOffset={this.updateOffset}
                      debouncedReloadIfNeeded={this.debouncedReloadIfNeeded.bind(this)}
                      pageSize={this.pageSize}
                      elements={this.orders}
                    />
                    {this.orders.results.map(order => {
                      return (
                        <OrderCard
                          key={order.id}
                          dishes={order.json_order}
                          price={order.price}
                          state={order.state}
                          receiveTime={order.finish_prep_time}
                          receiveMethod={order.receive_method}
                          state_translated={order.state_order}
                          fmt_created_at={order.fmt_created_at}
                          cancelNote={order.cancel_note}
                          translate={translate}
                          defaultLang={this.props.defaultLang}
                          currentLang={this.props.currentLang}
                        />
                      )
                    })}
                  </div>
                ) : (
                  <li className='li-request'>
                    <p> {translate(msg.emptyMyOrders)} </p>
                  </li>
                )
                }</Spin>
            </PageUl>)}
        </div>

      </PageFrame>
    )
  }
}

export default withRouter(injectIntl(MyOrders))
