import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { SmartRequests } from '../../utilities'
import Valet from './Valet'
import PageView from '../PageView'
import Fetching from '../../components/Fetching';
import BuildingInfo from '../Main/buildingInfo';
import * as mqttClient from '../Dashboard/mqttClient'

const INITIAL_PATH_DEEPNESS = 5 // also the number of slashes ('/')


class Guest extends React.Component {

  state = {
    theme: '',
    foundUnitAlreadyLoggedIntoBuilding: undefined,
    shouldIMoveToDashboard: false,
    mqttTopicMessage: null,
    newMqttMessage: false,
    mqttMessage: null,
    loadingBuilding: true
  }

  setupGuestMQTT = async (guestPhone, client_secret) => {
    console.log("Setting up MQTT client for guest")
    await mqttClient.setup(true, guestPhone, client_secret)
    const buildingId = this.state.building.id
    // For guests, we only subscribe to building-wide topics
    const guestPrefix = `${buildingId}/guest/+/service/`
    const nonUnitPrefix = `${buildingId}/+/+/service/`

    const handlerSpecialCase = (rawTopic, message) => {
      console.log('Guest MQTT message received:', rawTopic, message)
      const topic = rawTopic.split('/service/')[1]
      this.setState({
        newMqttMessage: true,
        mqttTopicMessage: topic, 
        mqttMessage: message
      })
    }

    // Subscribe to menu and schedule updates for restaurant pages
    if (this.props.location.pathname.includes('/guest/restaurant/')) {
      console.log('Subscribing to menu updates:', guestPrefix + 'menu_update')
      mqttClient.subscribeToTopic(
        nonUnitPrefix + 'menu_update',
        handlerSpecialCase
      )

      console.log('Subscribing to schedule updates:', guestPrefix + 'schedule_update')
      mqttClient.subscribeToTopic(
        nonUnitPrefix + 'schedule_update',
        handlerSpecialCase
      )
    }
    
    
    mqttClient.onConnect(() => {
      console.log('Guest connected to real time synchronization')
    })

    mqttClient.onError((error) => {
      console.log('Guest MQTT ERROR:', error)
    })
  }

  componentDidMount() {
    this.loadStyle()
    this.checkForLogginStatus()
  }

  componentWillUnmount() {
    // Clean up MQTT connection when component unmounts
    if(mqttClient){
      mqttClient.disconnect()
    }
  }

  subscribeToGuestChannel = (channel, handlerMqttPageMessages)=>{
    console.log('Subscribing to guest messages:', channel)
    mqttClient.subscribeToTopic(
      channel,
      handlerMqttPageMessages
    )
  }

  loadStyle = async () => {
    let match = this.props.match
    let buildingName = match.params.buildingName
    let url = `/${buildingName}/resident/guest/ws/2/building`
    SmartRequests.get(url).then(response => {
      let building = response.data.building
      this.setState({ theme: building.style,
                      building: building,
                      loadingBuilding: false
       })
    }).catch(e => {
      console.log(`Error requesting building info. Default style will be used. ${e}`)
    })
  }
  
  pageMessageReceivedCallback = () => {
    this.setState({newMqttMessage: false})
  }

  checkForLogginStatus = () => {
    let foundUnitAlreadyLoggedIntoBuilding
    const match = this.props.match
    const buildingInPath = match.params.buildingName
    BuildingInfo.getStoredBuildings().then(buildings => {
      const buildingFoundInStorage = buildings.find((building) => building.name === buildingInPath)
      if (buildingFoundInStorage) {
        const url = '/resident/ws/info'
        SmartRequests.head(url, null, { ignoreAuthError: true }).then((response) => {
          let error = response.error
          if (error) {
            console.log(error)
            foundUnitAlreadyLoggedIntoBuilding = false
          } else {
            foundUnitAlreadyLoggedIntoBuilding = buildingFoundInStorage.units[0]
          }
          this.setState({ foundUnitAlreadyLoggedIntoBuilding })
        }).catch((error) => {
          console.log(`Could not check login status, entering as guest... ${error}`)
          foundUnitAlreadyLoggedIntoBuilding = false
          this.setState({ foundUnitAlreadyLoggedIntoBuilding })
        })
      } else {
        console.log(`Building not found into user's logged buildings list, entering as guest...`)
        foundUnitAlreadyLoggedIntoBuilding = false
        this.setState({ foundUnitAlreadyLoggedIntoBuilding })
      }
    })
  }

  getCurrentUrlMappedToResidentUrl = () => {
    // FIXME: refactor me when guest valet is also into 
    const location = this.props.location
    const routeMatch = this.props.match
    const path = location.pathname
    const building = routeMatch.params.buildingName
    const theRegex = new RegExp(`${building}/guest/page/(.*)`)
    const pageSubpath = path.match(theRegex)[1]
    const residentUrl = `/${building}/${this.state.foundUnitAlreadyLoggedIntoBuilding}/dashboard/${pageSubpath}`
    return residentUrl
  }

  render() {
    const pathMatchesCustomPages = this.props.location.pathname.match(/^\/[^\/]*\/guest\/page\//)
    if (this.state.foundUnitAlreadyLoggedIntoBuilding === undefined) {
      return <Fetching enabled />
    } else if (this.state.foundUnitAlreadyLoggedIntoBuilding && pathMatchesCustomPages) {
      let pageUrl = this.getCurrentUrlMappedToResidentUrl()
      return <Redirect to={pageUrl} />
    }

    return (
      <div id='theme-container' className={[this.state.theme, 'guest'].join(' ')}>
        <Switch className="class-test">
          <Route path='/:buildingName/guest/valet/:hash' strict render={ route => 
            <Valet
              buildingName={route.match.params.buildingName}
              hash={route.match.params.hash}
            /> 
          } />
          <Route path='/:buildingName/guest/page/:pageName/' strict render={ route => 
              <PageView
                pageType='custom'
                isGuest
                buildingName={route.match.params.buildingName} 
                pageName={route.match.params.pageName}
                level={INITIAL_PATH_DEEPNESS}
                parentPath={'/:buildingName/guest/page/'}
              />
          } />
          <Route 
          path='/:buildingName/guest/restaurant/page/:pageId/:delivery_place?'
          render={ route => {

              if (this.state.loadingBuilding) {
                return null
              }
              return (
                <PageView
                  pageType='restaurant'
                  isGuest
                  buildingName={route.match.params.buildingName} 
                  pageId={route.match.params.pageId}
                  level={INITIAL_PATH_DEEPNESS}
                  parentPath={'/:buildingName/guest/restaurant/page/'}
                  data = {{
                    newMqttMessage:this.state.newMqttMessage,
                    mqttTopicMessage:this.state.mqttTopicMessage,
                    mqttMessage:this.state.mqttMessage,
                    delivery_place: route.match.params.delivery_place
                  }}
                  methods={{
                      pageMessageReceivedCallback: this.pageMessageReceivedCallback,
                      subscribeToGuestChannel: this.subscribeToGuestChannel,
                      setupGuestMQTT: this.setupGuestMQTT,
                      disconnectMQTT: () => mqttClient? mqttClient.disconnect() : null
                    }
                  }
                  reqData={
                    {
                      building:  this.state.building
                    }
                  }
                  />
                )
              }
            }
          />
          <Redirect to="/access/login"/>
        </Switch>
      </div>
    )
  }
}

export default withRouter(Guest)
