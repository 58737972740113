import React from 'react';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router-dom';
import { Button, List, Modal, Typography, Divider, Radio } from 'antd';
import { EditOutlined, DeleteOutlined, CheckOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';

import msg from './messages.js';
const { Text, Title } = Typography;

const EditCancel = ({modifyModal, removeFromOrder}) => {
  
  return (
    <div className='two-buttons-container'>
      <Button type="primary" onClick={modifyModal}>
        <EditOutlined />
      </Button>
      <Button type="danger" onClick={removeFromOrder}>
        <DeleteOutlined />
      </Button>
    </div>
  )
}

class OrderChangedModal extends React.Component {

    constructor(props) {
      super(props);
      this.state = { canClose: true }
      this.existsUnacceptableDish = false
      this.translate = this.props.intl.formatMessage // Get translation function from props
    }

    handleOk = () => {
      if (this.existsUnacceptableDish) {
        this.setState({ canClose: false, highlightTitles: true, animateTitles: true })
      } else {
        const { defaultLang } = this.props
        this.setState({ canClose: true })
        const changedDishes = this.changedDishes
        const newOrder = changedDishes.map((dish) => {
          if (dish.new != null) { 
            const updatedSelectedExtras = dish.old.selectedExtras.map((extra) => {
              return dish.new.extras.find((e) => e.name[defaultLang] == extra.name[defaultLang])
            }).filter((updatedExtra) => updatedExtra != null)
            return {
              ...dish.new,
              quantity: dish.old.quantity,
              selectedCondiments: dish.old.selectedCondiments,
              selectedExtras: updatedSelectedExtras
            }
          }
        }).filter((dish) => dish != null)
        this.props.onOk(newOrder)
      }
    }

    areDifferent = (oldDish, newDish) => {
      if (newDish == null) {
        return true
      }

      const defaultLang = this.props.defaultLang
      const currentLang = this.props.currentLang

      const nameChanged = (oldDish.name[currentLang] || oldDish.name[defaultLang]) != (newDish.name[currentLang] || newDish.name[defaultLang])
      const priceChanged = oldDish.price != newDish.price
      const extrasChanged = !isEqual(oldDish.extras, newDish.extras)
      const optionGroupsChanged = !isEqual(oldDish.option_groups, newDish.option_groups)
      return newDish == null || nameChanged || priceChanged || extrasChanged || optionGroupsChanged
    }

    getDishesChanged = (order, dishes) => {
      // Return an array of objects changedDishes where changedDishes[i].old is the original dish, changedDishes[i].new is the new one and changedDishes[i].indexInOrder is the index of the dish in the order
      let changedDishes = []

      for (let i=0; i<order.length; i++) {
        const updatedDish = dishes.reduce((acc, dish) => {
          const dishFound = dish.category_plates.find(plate => plate.id === order[i].id)
          return dishFound ? {...dishFound, category: dish.category} : acc
        }, null)
        console.log('order[i]: ', order[i])
        console.log('updatedDish: ', updatedDish)

        changedDishes.push({old: order[i], new: updatedDish, indexInOrder: i})
      }
      return changedDishes
    }

    selectedOptionGroupsStillExist = (oldDish, newDish) => {
      const defaultLang = this.props.defaultLang
      const currentLang = this.props.currentLang
      let res = true
      Object.keys(oldDish.selectedCondiments).forEach(key => {
        oldDish.selectedCondiments[key].selected.forEach(sel => {
          const OG = newDish.option_groups.find(optionGroup => optionGroup.option_group_name[currentLang] == key)
          const found = OG && OG.options.find(option => option[defaultLang] == sel[defaultLang])
          if (!found) {
            res = false
          }
        })
      })
      return res
    }

    /**
     * @param {{new: {price: int, extras: {name: {defaultLang: string}, price: int}}, old: {price: int, selectedExtras: {name: {defaultLang: string}, price: int}}}[]} differentDishes
     * @memberof OrderChangedModal
     * @returns {{int,int,int}}
     */
    calculateTotalPriceDifference = (differentDishes) => {
      const defaultLang = this.props.defaultLang
      let totalOld = 0
      let totalNew = 0
      let difference = 0
      console.log('differentDishes: ', differentDishes)
      differentDishes.forEach(dish => {
        // Update selected extras so it only shows the extras that still exist
        const updatedSelectedExtras = dish.old.selectedExtras.map((extra) => {
          return dish.new!=null ? dish.new.extras.find((e) => e.name[defaultLang] == extra.name[defaultLang]) : null
        }).filter((updatedExtra) => updatedExtra != null)

        const oldPrice = dish.old.quantity * this.calculatePriceWithExtras(dish.old.price, dish.old.selectedExtras)
        const newPrice = dish.new!=null ? dish.old.quantity * this.calculatePriceWithExtras(dish.new.price, updatedSelectedExtras) : 0

        difference += newPrice - oldPrice
        totalOld += oldPrice
        totalNew += newPrice
      })
      return { old: totalOld.toFixed(2), new: totalNew.toFixed(2), difference: difference }
    }

    /**
     * @param {int} price
     * @param {{price: int}[]} extras
     * @memberof OrderChangedModal
     * @returns {int}
     */
    calculatePriceWithExtras = (price, extras) => {
      return price + extras.reduce((acc, extra) => acc + extra.price, 0)
    }
    
    

    render() {
      const { order, dishes, visible, defaultLang, currentLang, modifyModal, removeFromOrder, isMobile } = this.props;
  
      this.changedDishes = this.getDishesChanged(order, dishes)
      this.existsUnacceptableDish = false

      const changedDishes = this.changedDishes

      const differentDishes = changedDishes.filter((dish) => this.areDifferent(dish.old, dish.new))

      if (differentDishes.length == 0) {
        this.handleOk()
      }

      const animate = this.state.animateTitles
      const highlight = this.state.highlightTitles

      const totalPrice = this.calculateTotalPriceDifference(differentDishes)
      return (
        <Modal
          transitionName='ant-modal-slide-up'
          className={'modal order-changed-modal' + (isMobile ? ' mobile' : '')}
          title={
            <Title level={4}>
              {this.translate(msg.OrderChangedModalTitle)}
            </Title>}
          visible={visible}
          closable={false}
          onOk={this.handleOk}
          okText={this.translate(msg.confirm)}
          footer={ // Footer Buttons
            <div className='modal-footer'>
              {totalPrice.difference !== 0 ? (
                <Title level={4} className='modal-price-difference' style={{ margin: 0}}>
                  <p style={{color: 'black', margin: 0}} >
                    {this.translate(msg.TotalPrice)}<s style={{color: 'grey'}}>${totalPrice.old}</s> <ArrowRightOutlined /> ${totalPrice.new}
                  </p>
                </Title>
              ) : (
                <Title level={4} className='modal-price-difference'style={{ margin: 0 }}>{this.translate(msg.TotalPrice)} ${totalPrice.new}</Title> 
              )}
              <div>
                <Button size='large' type="primary" onClick={this.handleOk}>
                  {this.translate(msg.confirm)}
                </Button>
              </div>
            </div>
          }
        >
          <div>
            <List
              dataSource={changedDishes}
              itemLayout='horizontal' // Makes buttons show on side
              renderItem={(changedDish, index) => {
                const newDish = changedDish.new
                const dish = changedDish.old
                
                // If new dish is null => show crossed over card
                if (newDish == null) {
                  return (
                    <List.Item 
                    style={{marginRight: '1vw'}}
                  >
                    <div className='order-changed-item'>
                      <p style={{color: 'black'}}>
                        <s style={{color: 'grey'}}>{(dish.name[currentLang] || dish.name[defaultLang])}</s> <ArrowRightOutlined /> {this.translate(msg.DishNotAvailable)}
                      </p>
                    </div>
                  </List.Item>
                  )
                }


                // Update selected extras so it only shows the extras that still exist
                const updatedSelectedExtras = dish.selectedExtras.map((extra) => {
                  return newDish.extras.find((e) => e.name[defaultLang] == extra.name[defaultLang])
                }).filter((updatedExtra) => updatedExtra != null)

                // Update selected condiments so it only shows the condiments that still exist
                let updatedSelectedCondiments = {}
                newDish.option_groups.forEach((og) => { // For each option group in new dish, set selected to empty array
                  updatedSelectedCondiments[og.option_group_name[defaultLang]] = {group_name: og.option_group_name, selected: []}
                })
                Object.entries(dish.selectedCondiments).forEach(([key, value]) => { // For each option group
                  const option_group = newDish.option_groups.find((og) => og.option_group_name[defaultLang] == key) // key is default group name
                  if (option_group) { // Update selections
                    const options_names = option_group.options.map(o => o[defaultLang])
                    const selections = value.selected.filter((sel) => options_names.includes(sel[defaultLang]))
                    updatedSelectedCondiments[key] = {group_name: value.group_name, selected: selections}
                  }
                })

                // Get Values to show
                const oldName = (dish.name[currentLang] || dish.name[defaultLang])
                const newName = (newDish.name[currentLang] || newDish.name[defaultLang])
                const quantityToShow = dish.quantity > 1 ? `${dish.quantity} x ` : ''
                const oldPrice = dish.quantity * this.calculatePriceWithExtras(dish.price, dish.selectedExtras)
                const newPrice = dish.quantity * this.calculatePriceWithExtras(newDish.price, updatedSelectedExtras)


                // Get what changed
                const nameChanged = oldName != newName
                const priceChanged = oldPrice != newPrice
                const extrasChanged = !isEqual(dish.extras, newDish.extras)
                const selectedExtrasStillExist = !extrasChanged || (dish.selectedExtras == null) || dish.selectedExtras.reduce((acc, extra) => acc && newDish.extras.find((e) => e.name[defaultLang] == extra.name[defaultLang]) != null, true)
                const optionGroupsChanged = !isEqual(dish.option_groups, newDish.option_groups)
                const selectedOptionGroupsStillExist = !optionGroupsChanged || this.selectedOptionGroupsStillExist(dish, newDish)
                
                // all dishes have at least the minimum number of options selected
                const minOptionsSelected = (newDish.option_groups == null) || (newDish.option_groups.reduce((acc, optionGroup) => {
                  return (acc && (optionGroup.min_quantity == null || optionGroup.min_quantity <= updatedSelectedCondiments[optionGroup.option_group_name[defaultLang]].selected.length))
                }, true))

                // all dishes have at most the maximum number of options selected
                const tooManyOptionsSelected = (newDish.option_groups == null) || (newDish.option_groups.reduce((acc, optionGroup) => {
                  return (acc || !(optionGroup.max_quantity == null || optionGroup.max_quantity >= updatedSelectedCondiments[optionGroup.option_group_name[defaultLang]].selected.length))
                }, false))

                

                // if some dish has a selected condiment that is not in the new dish or doesnt have enough options selected / too many options selected => cant accept changes
                this.existsUnacceptableDish = this.existsUnacceptableDish || !selectedExtrasStillExist || !selectedOptionGroupsStillExist || !minOptionsSelected || tooManyOptionsSelected

                if (!this.areDifferent(dish, newDish)) {
                  return <></> // If dishes are not different, dont show anything
                }
                return ( // Dishes are considered different
                  <List.Item 
                    style={{marginRight: '1vw'}}
                  >
                    <div className='order-changed-item'>
                      <div>
                        {
                          // Show Dish name
                          nameChanged ?
                          (<p style={{color: 'black'}}>
                            <s style={{color: 'grey'}}>{quantityToShow} {oldName}</s> <ArrowRightOutlined /> {quantityToShow} {newName}
                          </p>)
                          :
                          (<p style={{color: 'black'}}>
                            {quantityToShow} {newName}
                          </p>)
                        }
                        {
                          // Show dish price
                          priceChanged ?
                          (<p style={{color: 'black'}}>
                            <s style={{color: 'grey'}}>${oldPrice.toFixed(2)}</s> <ArrowRightOutlined /> ${newPrice.toFixed(2)}
                          </p>)
                          :
                          (<p style={{color: 'black'}}>
                            ${newPrice.toFixed(2)}
                          </p>)
                        }
                        {optionGroupsChanged && selectedOptionGroupsStillExist && (<p>{this.translate(msg.OptionsModified)}</p>)}
                        {optionGroupsChanged && !selectedOptionGroupsStillExist && (
                          <p
                            onAnimationEnd={() => {this.setState({ animateTitles: false })}}
                            className={`${animate ? ' title-jump-animation':''} ${highlight? ' highlight':''}`}
                          >{this.translate(msg.OptionsDontExist)}</p>
                        )}
                        {extrasChanged && selectedExtrasStillExist && (<p>{this.translate(msg.ExtrasModified)}</p>)}
                        {extrasChanged && !selectedExtrasStillExist && (
                          <p
                            onAnimationEnd={() => {this.setState({ animateTitles: false })}}
                            className={`${animate ? ' title-jump-animation':''} ${highlight? ' highlight':''}`}
                          >{this.translate(msg.ExtrasDontExist)}</p>
                          )}
                        {!minOptionsSelected && (
                          <p
                            onAnimationEnd={() => {this.setState({ animateTitles: false })}}
                            className={`${animate ? ' title-jump-animation':''} ${highlight? ' highlight':''}`}
                          >{this.translate(msg.MinimumSelectionsChanged)}</p>
                        )}
                        {tooManyOptionsSelected && (
                          <p
                            onAnimationEnd={() => {this.setState({ animateTitles: false })}}
                            className={`${animate ? ' title-jump-animation':''} ${highlight? ' highlight':''}`}
                          >{this.translate(msg.MaximumSelectionsChanged)}</p>
                        )}
                      </div>
                      <EditCancel
                        modifyModal={() => modifyModal(changedDish.indexInOrder, false, {...newDish, selectedExtras: updatedSelectedExtras, selectedCondiments: updatedSelectedCondiments, category: dish.category, quantity: dish.quantity}, true)}
                        removeFromOrder={() => removeFromOrder(changedDish.indexInOrder, dish.quantity)}
                      />
                    </div>
                  </List.Item>
                )
              }}
            />
            <Divider />
          </div>
        </Modal>
      )
    }
}

export default injectIntl(withRouter(OrderChangedModal));