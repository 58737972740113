import React, { Component } from 'react';
import { remote, reservations } from './helpers';
import DatePicker from './DatePicker';
import LoadingComponent from '../../../components/LoadingComponent';
import { SmartRequests } from '../../../utilities/index.js';
import Board from '../../../components/Board.js';
import Square from '../../../components/Square.js';
import PageUl from '../../../components/PageUl.js';
import TransitionSwitch from '../../../components/TransitionSwitch.js';
import Header from '../../../containers/PageView/Header';
import PageFrame from '../../../components/PageFrame.js';
import { Route } from 'react-router-dom';
import { injectIntl } from "react-intl";
import msg from './messages'
import { Button, message } from 'antd';
import moment from 'moment';

const RESERVABLES_PATH = '/:buildingName/:unit/dashboard';

class CalendarPage extends Component {
  render() {
    return (
      <div className="page-view">
        <Header>{this.props.reservable.name}</Header>
        <DatePicker
          {...this.props.routerProps}
          level={this.props.level}
          name={this.props.name} 
          groupData={this.props.reservable} 
          unitID={this.props.unit}
          removeReservation={this.props.removeReservation}
          addReservation={this.props.addReservation}
          myReservations={this.props.myReservations}
          reservations={this.props.reservations}
          reqData={this.props.reqData}
        />
      </div>
    );
  }
}



class Reservables extends Component {
  constructor(props) {
    super(props);
    var sortedReservations = reservations.sortByReservable(this.props.reservations);
    this.state = {
      'reservables': [],
      'sortedReservations': reservations.sortByReservable(this.props.reservations),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reservations !== this.props.reservations) {
      this.setState({'sortedReservations': reservations.sortByReservable(this.props.reservations)});
    }
  }

  cancelReservation(reservation) {
    SmartRequests.post('reservations/' + reservation + '/events', {event:'cancel'} ).then( resp => {
        if (resp.status === 200) {
          this.props.removeReservation(reservation);
          message.success(this.props.intl.formatMessage(msg.cancelSuccessfully));
        }
    }).catch((error) => {
      message.error(this.props.intl.formatMessage(msg.errorCanceling))
    });
  }

  canCancel = (res) => {
    const { min_time_in_advance, min_advance_unit, period } = this.props.reservables.find(reservable => reservable.id === res.group_id);
    return remote.canCancelReservation(min_time_in_advance, min_advance_unit, moment(res.start_date), period!=0);
  }

  render() {
    const t = this.props.intl.formatMessage
    return (
      this.props.reservables.length === 0 
      ? <LoadingComponent isLoading style={{"display": "flex", "justify-content": "center"}} />
      :<div className="reservables">
        {
          <Board>
          {
            this.props.reservables.map(
              (reservable, index) => <Square name={`reservable_${reservable.id}`} to={reservable.id} title={reservable.name} level={this.props.level + 1} key={'square-'+index}/> 
            )
          }
          </Board>
        }
        {
          Array.isArray(this.props.reservations) && this.props.reservations.length !== 0?
          (
            <PageUl>
              {this.props.reservations.map( (res, i) => {
                const canBeCancelled = this.canCancel(res)
                return (
                  <li className="li-request" key={i}>
                    <p className="title"   >{ res.name           }</p>
                    <p className="subtitle">{ res.item_name      }</p>
                    <p className="date"    >{ res.fmt_start_date }</p>
                    { res.symbol_price && res.symbol_price.includes("N/A") ? <p>{t(msg.priceNotSpecified)}</p> : <p>{t(msg.price) + ": " + res.symbol_price}</p> }
                    
                    {
                      <LoadingComponent isLoading={!(res.id && res.state === 'reserved')}>
                        <Button 
                          size='small' 
                          type='danger' 
                          className="link" 
                          style={{ marginTop: '0.5rem' }} 
                          onClick={this.cancelReservation.bind(this, res.id)} 
                          disabled={!canBeCancelled}
                          title={canBeCancelled ? `${res.name}` : t(msg.cannotCancel)}
                        >
                          {t(msg.cancel)}
                        </Button>
                      </LoadingComponent>
                    }
                    
                  </li>
                )
              })}
            </PageUl>
          )
          : null
        }

        <TransitionSwitch level={this.props.level + 1}>
          {
            this.props.reservables.map(
              (reservable, index) => (
                <Route key={'route-'+index} path={RESERVABLES_PATH+'/'+this.props.name+'/'+reservable.id} render={
                  (routerProps) => {
                    const pageInfo = {
                      title: reservable.name,
                      content: reservable.description,
                    };
                    return (<PageFrame>
                      <Header pageInfo={pageInfo}>{reservable.name}</Header>
                      <DatePicker
                        {...this.props.routerProps}
                        level={this.props.level + 1}
                        name={this.props.name} 
                        groupData={reservable} 
                        unitID={this.props.unit}
                        removeReservation={this.props.removeReservation}
                        addReservation={this.props.addReservation}
                        updateReservation={this.props.updateReservation}
                        myReservations={this.props.reservations}
                        reservations={this.state.sortedReservations[reservable.id] || []}
                        reqData={this.props.reqData}
                        basepath={routerProps.match.path}
                        t={this.props.intl.formatMessage}
                      />
                    </PageFrame>);
                  }
                }/>
              )
            )
          }
        </TransitionSwitch>
      </div>
    );
  }
};

export default Reservables = injectIntl(Reservables)