import React from 'react';
import { Route, withRouter } from "react-router"
import { SmartRequests } from '../../../utilities'
import { injectIntl} from "react-intl";
import Board from '../../../components/Board';
import { Icon, message, Button} from 'antd';
import PanicSquare from '../../../components/PanicSquare';
import PageFrame from '../../../components/PageFrame';
import SmartLink from '../../../components/SmartLink';
import PageView from '../../PageView';
import msg from './messages'
import { PlusCircleOutlined} from '@ant-design/icons';
import Description from '../../PageView/Description';
import Header from '../../PageView/Header';
import TransitionSwitch from '../../../components/TransitionSwitch';

class Panic extends React.Component  {
    constructor(props) {
      super(props)
      this.state = {
        showSquares: true,
        searching: false,
        pageOffset: 0,
        contact: null,
        emergency_types: [],
      }
    }

  componentDidMount() {
    console.log('mounting panic')
    this.building_and_unit_emergency_settings()
    if (this.props.hidePageText) {
      this.props.hidePageText()
    }
  }

  building_and_unit_emergency_settings(){
    SmartRequests.get('emergency_settings').then( resp => {
      if (resp.status === 200) {
        this.setState({ 
          contact : resp.data.contact,
          emergency_types : resp.data.emergency_types,
        });

        this.props.methods.setActiveEmergency(resp.data.active_emergency);

      }}).catch( err => { 
        console.log("Error get: " + err)
      })
  }

  handleSquareClick = (emergencyType) => {
    const activeEmergency = this.props.data.activeEmergency;

    if(!activeEmergency || (activeEmergency.type !== emergencyType && activeEmergency.state === "pending")){
      SmartRequests.post('trigger_emergency', { emergency_type: emergencyType })
      .then(response => {
        this.props.methods.setActiveEmergency(response.data.active_emergency);
        console.log('Emergency alert sent successfully:', response);
      })
      .catch(error => {
        console.error('Error sending emergency alert:', error);
      });
    }
    
  };


  handleCancelClick = () => {
    const baseUrl = 'cancel_emergency/';
    const postId = this.props.data.activeEmergency.emergency_id; 

    SmartRequests.post(`${baseUrl}${postId}`).then(response => {
      console.log('Cancel alert sent successfully:', response);
      message.info(this.props.intl.formatMessage(msg.cancelAlertStaff));
      this.props.methods.setActiveEmergency(null);
      this.props.history.goBack();
    })
    .catch(error => {
      message.error(this.props.intl.formatMessage((msg.ErrorRequest)))
    });
    
  }

  render() {
    const t = this.props.intl.formatMessage

    let handled_by = ""
    let user = ""
    let type = ""

    if (this.props.data.activeEmergency){
      let { data } = this.props;
      user = data.activeEmergency.user;
      type = data.activeEmergency.type;

      if ("handled_by" in data.activeEmergency){
        handled_by = data.activeEmergency.handled_by;
      }
    }

    return (
      <div>
        
        <Board>{
          this.state.emergency_types.map( 
            (type, i) => 
              <PanicSquare
                title={type}
                type={type}  
                key={i}
                level={this.props.level}
                className={type}
                onClick={this.handleSquareClick}  
                activeType={this.props.data.activeEmergency !== null ? this.props.data.activeEmergency.type : ""}
              />
           )}
        </Board>

        <div className='settings-container'>
          <SmartLink to="panic-setting/" id="link-panic-add-contact" level={this.props.level+1} className="link-settings">
            {
              this.state.contact
              ? ( <Icon type="setting"/>)
              : ( <PlusCircleOutlined className='plus-item'/>)
            }
              {t(msg.settings_panic)}
          </SmartLink>
        </div>
        
        {this.props.page.text ? <Description text={this.props.page.text } /> : null}

        <TransitionSwitch level={this.props.level + 1}>
          <Route path={'/:buildingName/:unit/dashboard/emergency/panic-setting/'} render={() => 
            <PageView 
              page={{...this.props.page, name: "panic-setting", type: "custom", title: t(msg.addEmergencyContact), text: t(msg.descAddEmergencyContact)}}
              key={this.props.page.id}
              subPages={""}
              unit={this.props.unit}
              data={this.props.data}
              reqData={this.props.reqData}
              parentPath={"dashboard/emergency/"}
              methods={{...this.props.methods, updateContact: this.building_and_unit_emergency_settings.bind(this)}}
              level={this.props.level + 1}
              initialData={this.state.contact}
              url={"emergency_settings"}
            />
          }/>
        </TransitionSwitch>

        {this.props.data.activeEmergency &&
          
            <Route path={'/:buildingName/:unit/dashboard/emergency/activate-alarm'} render={() => 
              <PageFrame>
                <Header></Header>
                <div className='body-alert'>
                  <div className="container-emergency">
                    <div className="alert-header">
                      <h1 className="help-title">{t(msg.HelpRequested)}</h1>
                    </div>
                    <div>
                    <p className="help-text" dangerouslySetInnerHTML={{ __html: t(msg.helpRequestedDetails, {user, type: t(msg[this.props.data.activeEmergency.type])}) }} />
                    </div>
                    <div className='footer-container'>
                      <div className='date'><strong>{t(msg.date)}:</strong> {this.props.data.activeEmergency.date}</div>
                      {this.props.data.activeEmergency.state === "pending" &&
                        <Button type="danger" className="cancel-button" onClick={this.handleCancelClick}>{t(msg.cancel)}</Button> 
                      }
                    </div>
                    <div className='emergency-state'>
                      <p className='emergency-state-title'>{t(msg[this.props.data.activeEmergency.state], {handled_by})}</p>
                    </div>
                  </div>
                </div>
              </PageFrame>
            }/>
        }

      </div>  
    );
  }
}

export default Panic = withRouter(injectIntl(Panic));
