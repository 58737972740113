import React from 'react';
import { Layout, Card, Button, List, Typography, Divider, Badge, Spin, Alert, Icon, Modal } from 'antd';
import { ZoomableImage } from '../../../components/ZoomableImage'
import { ShoppingCartOutlined, DoubleRightOutlined, DoubleLeftOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { injectIntl } from "react-intl";
import { Route, withRouter } from 'react-router-dom';
import { SmartRequests } from '../../../utilities';
import { isEqual } from 'lodash';
import TransitionSwitch from '../../../components/TransitionSwitch.js'
import MenuHeader from './MenuHeader.js'
import moment from 'moment-timezone';
import 'moment/locale/es';
import Fetching from '../../../components/Fetching';
import PhoneModalValidation from './PhoneValidation.js';

import msg from './messages.js';
import ConfirmOrderModal from './ConfirmOrderModal';
import ModifyModal from './ModifyModal';
import OrderChangedModal from './OrderChangedModal.js';
import MyOrders from './MyOrders.js'
import DishVariantsModal from './DishVariantsModal.js';
import { CartItem } from './CartItem.js';
import { PlusMinus } from '../../../components/PlusMinus.js';
import * as CustomStorage from '../../../utilities/customStorage';

const { Content, Sider, Footer } = Layout;
const { Title, Text, Paragraph } = Typography;


const MenuComponent = ({ dishes, categoryRefs, addToOrder, removeFromOrder, modifyModal, order, isMobile, defaultLang, currentLang, openDishVariantsModal }) => {
  return (
    <>
      <style> {`.ant-badge {width: 100%;}`} </style>
      {dishes.map((categoryDish, indexCategory) => (
        <div key={categoryDish.category[defaultLang]} ref={categoryRefs[categoryDish.category[defaultLang]]}>
          <Title name='category_name' className='category-title' level={4}>{categoryDish.category[currentLang] || categoryDish.category[defaultLang]}</Title>
          <List className='food-list-center-sm'
            grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 3 }}
            style={isMobile ? { maxWidth: 400, margin: 'auto' } : {}}
            dataSource={categoryDish.category_plates}
            renderItem={(dish, indexPlate) => {
              const quantityInOrder = order.filter((d) => d.id == dish.id).reduce((n, { quantity }) => n + quantity, 0)
              const hasVariants = order.filter((d) => d.id == dish.id).length > 1
              const modifyable = (dish.extras != null && dish.extras.length > 0) || (dish.option_groups != null && dish.option_groups.length > 0)
              dish.category = categoryDish.category // this is needed for the modifyModal
              let indexInOrder;
              if (!hasVariants) {
                indexInOrder = order.findIndex((d) => d.id == dish.id)
              }
              return (
              <List.Item
              style={{paddingBottom: 
                isMobile && 
                order.length > 0 && 
                indexPlate === categoryDish.category_plates.length - 1 &&
                indexCategory === dishes.length - 1
                  ? '45px' 
                  : undefined}}
                onClick={() => {
                  if (quantityInOrder > 0) {
                    // open dishVariants if already in order, else open modifyModal
                      openDishVariantsModal(dish)
                  } else {
                    modifyModal(-1, true, { ...dish, selectedCondiments: {}, selectedExtras: [], category: categoryDish.category, quantity: 1 })
                  }
                }}
              >
                <Card
                  name='dish_card'
                  hoverable
                  style={isMobile ? { maxWidth: 400, height: 'auto', margin: '0 auto 0 auto', borderRadius: 15, overflow: "hidden" } : 
                  { width: '100%', height: '14rem', borderRadius: 15, overflow: "hidden" }}
                  title={
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height:'7rem', fontSize:'1.1rem'}}
                    >
                      <div
                        className='card-title-desc'
                        style={{
                          maxWidth: dish.image_url ? 'calc(100% - 7rem)' : '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          minWidth: 0
                        }}
                      >
                        <Title
                          style={{ minWidth: 0, wordBreak: 'break-word', whiteSpace: 'normal' }}
                          ellipsis={{ rows: 2 }}
                          level={4}
                        >
                          {dish.name[currentLang] || dish.name[defaultLang]}
                        </Title>
                        <Paragraph
                          ellipsis={{ rows: 2 }}
                          style={{ minWidth: 0, wordBreak: 'break-word', whiteSpace: 'normal' }}
                        >
                          {dish.description[currentLang] || dish.description[defaultLang]}
                        </Paragraph>
                      </div>
                      {dish.image_url && (
                        <div onClick={(e) => e.stopPropagation()} className='card-image' style={{ width: '6rem', height: '6rem', overflow: 'hidden', borderRadius: '10px', border: '1px solid black' }}>
                            <ZoomableImage
                              src={dish.image_url}
                              alt={dish.name[currentLang] || dish.name[defaultLang]}
                              width="100%"
                              height="100%"
                            />
                        </div>
                      )}
                    </div>
                  }
                >
                  <Card.Meta
                    description={
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxHeight:'426px' }}  >
                        <Text strong>${dish.price.toFixed(2)}</Text>
                        <div
                          className={'quantity-in-order' + (quantityInOrder > 0 ? ' bg-black':'')}
                        >
                          {quantityInOrder > 0 ? quantityInOrder : '＋'}
                        </div>
                      </div>
                    }
                  />
                </Card>
              </List.Item>
            )}}
          />
        </div>
      ))}
    </>
  );
};

class FoodOrder extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      order: [],
      dishes: [],
      filteredDishes: [],
      optional_states: [],
      deliver_places: [],
      fetchingData: true,
      modifyModalVisible: false,
      modifyModalIndex: null,
      confirmOrderModalVisible: false,
      orderChangedModalVisible: false,
      dishVariantsModalVisible: false,
      dishVariant: null,
      modalClosing: false,
      isMobile: window.innerWidth <= 672,
      showCart: window.innerWidth > 672,
      menuChangedAlertVisible: false,
      recentlyFetchedWaitingTime: false,
      selectedTags: [],
      restaurant_name: this.props.page ? this.props.page.name : undefined, // set on menu restaurant fetch
      phoneValidated: !this.props.isGuest,
      guestName: '',
      guestUnit: '',
      guestPhone: '',
      tagsToShow: [],
      restaurant_schedule: {today: [], yesterday: [], close_indefinitely: false},
      willOpenAt: null,
      willCloseIn: -1,
      isClosed: false,
      allDay: false,
      orderIsOld: false,
      oldOrderDate: moment(),
    };
    this.pageId = this.props.page ? this.props.page.id : this.props.pageId
    this.isClosedInterval = null // For the repeating function to check if restaurant is still open
    this.translate = this.props.intl.formatMessage // Get translation function from props
    this.categoryRefs = {};
    this.defaultLang = this.props.reqData.building.locales.split(',')[0]
    this.client_secret = undefined;
  }

  isClosedCallback = () => {
    this.isClosed()
    
    const building_timezone = this.props.reqData.building.timezone
    let now = moment(moment.tz(building_timezone).format('HH:mm:ss'), "HH:mm:ss")
    let nextMin = now.clone().add(1, 'minutes').startOf('minute')
    
    setTimeout(() => {
      this.isClosed()
      this.isClosedInterval = setInterval(this.isClosed, 60000) // check if restaurant is still open every minute on the minute
    }, nextMin.diff(now, 'seconds') * 1000 + 5000)
    
  }

  componentDidMount() {
    this.props.methods.pageMessageReceivedCallback() //dont show warning of menu update if it changed when page was not loaded
    this.props.hidePageText()
    window.addEventListener('resize', this.updateDimensions); // so we can render component on resize

    // Check if there is an unfinished order
    const storageKey = "restaurant_order_" + this.pageId
    CustomStorage.getItem(storageKey).then(data => {
      if (!data) { // if data == null, does nothing
        return
      }
      const { order, updated_at} = JSON.parse(data)
      const now = moment()
      const time = now.diff(updated_at, 'minutes')
      if (order != null && !isEqual(order, [])) {
        if (time > 60) {
          // Show an alert that the order is old and give option to continue with it or start a new order
          this.setState({orderIsOld: true, oldOrderDate: updated_at})
        } else {
          this.setState({order: order, orderIsOld: false, oldOrderDate: updated_at})
        }
      }
    })
    if (this.props.isGuest) {
      this.getDishes(()=>this.isClosedCallback());
      CustomStorage.getItem('guest_data').then(guest_data => {
        if (!guest_data) {
          return
        }
        let parsedGuestData = JSON.parse(guest_data)
        let guestPhone = parsedGuestData.guestPhone
        let guestName = parsedGuestData.guestName
        let guestUnit = parsedGuestData.guestUnit
        let client_secret = parsedGuestData.client_secret
        let validated = parsedGuestData.validated
        if (guestPhone && validated) {
          SmartRequests.post(`/${this.props.buildingName}/resident/guest/ws/2/guest_sms/${this.pageId}/login_validated_user`, 
            {guestPhone, client_secret}).then(resp => {
            if (resp.status === 200 && resp.data.valid) {
              this.setState({guestPhone, phoneValidated: true, guestName: guestName, guestUnit: guestUnit})
              this.client_secret = client_secret
              this.props.setupGuestMQTT(guestPhone, client_secret)
            }else{
              CustomStorage.removeItem('guest_data').catch(e => console.error(e))
            }
          })
        }else if (guestPhone && !validated) {
          this.client_secret = client_secret
          this.setState({guestPhone, smsSent: true, guestName, guestUnit})
        }
      }).catch(e => console.error(e))
    }else{
    this.getDishes(()=>this.isClosedCallback())
    }
  }
  
  isClosed = () => {
    const building_timezone = this.props.reqData.building.timezone
    const now = moment(moment.tz(building_timezone).format('HH:mm:ss'), "HH:mm:ss")
    let openTime = this.state.openTime
    let closeTime = this.state.closeTime
    let isClosed = true;
    let willCloseIn = -1;
    let willOpenAt = null;
    let allDay = true;

    // if restaurant has no schedule set or is closed indefinetely.
    if (this.state.restaurant_schedule == null || this.state.restaurant_schedule.close_indefinitely) {
      this.setState({isClosed: true, allDay: allDay, willCloseIn: willCloseIn})
      return
    }

    let today = this.state.restaurant_schedule.today
    let yesterday = this.state.restaurant_schedule.yesterday

    console.log('now', now)
    console.log('today', today)
    console.log('yesterday', yesterday)
    
    if (today != null && today.length > 0) {
      today.forEach(timeBlock => {
        openTime  = moment(timeBlock.open_time, "HH:mm:ss") 
        closeTime = moment(timeBlock.close_time, "HH:mm:ss")

        console.log('openTime', openTime)
        console.log('closeTime', closeTime)

        if (now.isBefore(openTime)) {
          allDay = false
          if (openTime < willOpenAt || willOpenAt == null) {
            willOpenAt = openTime.format('h:mm a')
          }
        }

        if (openTime < closeTime && now.isBetween(openTime, closeTime)) {
          isClosed = false;
          willCloseIn = closeTime.diff(now, 'minutes') + 1
          console.log('seconds', closeTime.diff(now, 'seconds', true))
        }
        else if (openTime > closeTime && now.isAfter(openTime)) {
          isClosed = false;
          willCloseIn = closeTime.diff(moment("00:00", "HH:mm"), 'minutes') + moment("24:00", "HH:mm").diff(now, 'minutes') + 1
        }
      })
    }

    if (isClosed && yesterday != null && yesterday.length > 0) {
      yesterday.forEach(timeBlock => {
        openTime  = moment(timeBlock.open_time, "HH:mm:ss") 
        closeTime = moment(timeBlock.close_time, "HH:mm:ss")

        console.log('openTime', openTime)
        console.log('closeTime', closeTime)

        // ie, is open from 23:00 to 02:00 
        if (openTime > closeTime && !now.isBetween(closeTime, openTime)) {
          isClosed = false;
          willCloseIn = closeTime.diff(now, 'minutes') + 1
          console.log('seconds', closeTime.diff(now, 'seconds'))
        }
      })
    }

    console.log('isClosed', isClosed, 'willCloseIn', willCloseIn)
    this.setState({isClosed: isClosed, willCloseIn: willCloseIn, allDay: allDay, willOpenAt: willOpenAt})
  }

  filterDishes = (newSelectedTags) => {
    const filteredDishes = this.state.dishes.map(category => ({
      ...category,
      category_plates: category.category_plates.filter(dish =>{
        if (!dish.tags) {
          return false
        }else{
          return newSelectedTags.every(tag => dish.tags.includes(tag)) || false
        }
      } 
      )
    })).filter(category => category.category_plates.length > 0);
    this.setState({ filteredDishes })
  }

  componentDidUpdate() {
    // Reloads dishes when new mqtt message is received
    if (this.props.data && this.props.data.newMqttMessage) {
      const mqttTopic = this.props.data.mqttTopicMessage
      const mqttMessage = this.props.data.mqttMessage
      if (mqttTopic === `menu_update`) {
        if (mqttMessage.restaurant_id == this.pageId && mqttMessage.type_of_save == 'publish') {
          this.handleMenuUpdate()
        }
      } else if (mqttTopic === 'schedule_update') {
        if (mqttMessage.restaurant_id == this.pageId) {
          this.handleScheduleUpdate()
        }
      }
    }
  }

/**
 * Fetches the menu data for the current page and updates the component state
 * with the received data. It sets various state properties such as dishes, 
 * menu_id, tagsToShow, optional_states, restaurant_schedule, openTime, closeTime,
 * restaurant_title, and deliver_places based on the response. 
 * 
 * If the response is successful and contains menu data, it initializes category 
 * references for scrolling and sets the fetchingData state to false.
 * 
 * If an optional callback function is provided, it is called after the state 
 * has been updated.
 * 
 * In case of an error or if the response does not contain valid menu data, 
 * the state is updated to reflect that no dishes were fetched, and fetchingData 
 * is set to false.
 * 
 * @param {Function|null} callback - An optional callback function to be executed 
 *                                    after updating the state.
 */
  getDishes(callback = null) {
    let url = this.props.isGuest ? `/${this.props.buildingName}/resident/guest/ws/2/food_order/${this.pageId}` : `food_order/${this.pageId}`
    SmartRequests.get(url).then(resp => {
      if (resp.status === 200 && resp.data.menu != null) {
        resp.data.menu.forEach(category => {
          this.categoryRefs[category.category[this.defaultLang]] = React.createRef();// so we can scroll to category
        });
        const openTime = moment(resp.data.open_time, 'HH:mm')
        const closeTime = moment(resp.data.close_time, 'HH:mm')
        let tagsToShow = ['veggie', 'vegan', 'gluten_free'] // ids of the tags
        tagsToShow = tagsToShow.filter((tag) => {
          return resp.data.menu.some(dish => {
            return dish.category_plates.some(plate => {
              return plate.tags && plate.tags.includes(tag)
            })
          })
        })
        let deliver_places = resp.data.deliver_places
        if(resp.data.deliver_places && this.props.data && this.props.data.delivery_place){
          deliver_places.splice(0, 0, decodeURIComponent(this.props.data.delivery_place))
        }

        // make sure restaurant schedule is not null and all fields are defined
        let restaurant_schedule = {today: [], yesterday: [], close_indefinitely: false, ...resp.data.restaurant_schedule}

        this.setState({
          dishes: resp.data.menu,
          menu_id: resp.data.menu_id,
          tagsToShow: tagsToShow,
          optional_states: resp.data.optional_states,
          restaurant_schedule : restaurant_schedule,
          openTime: openTime,
          closeTime: closeTime,
          restaurant_title: resp.data.page_title,
          emptyMenu: false, country: resp.data.country,
          restaurant_name: this.state.restaurant_name? this.state.restaurant_name : resp.data.restaurant_name,
          fetchingData: false, deliver_places:deliver_places
        }, 
        () => {
          if (callback != null) {
            callback();
          }
        });             
      } else {
        this.setState({ dishes: [], fetchingData: false },
          () => {
            if (callback != null) {
              callback();
            }
          });
      }
    }).catch(e => this.setState({ dishes: [], fetchingData: false }));

  }

  getSchedule = (callback = null) => {
    let url = this.props.isGuest ? `/${this.props.buildingName}/resident/guest/ws/2/food_order/${this.pageId}/schedule` : 'food_order/' + this.pageId + '/schedule'
    SmartRequests.get(url).then(resp => {
      if (resp.status === 200) {
        let restaurant_schedule = {today: [], yesterday: [], close_indefinitely: false, ...resp.data}
        this.setState({
          restaurant_schedule : restaurant_schedule,
          fetchingData: false,
        }, 
        () => {
          if (callback != null) {
            callback();
          }
        });             
      } else {
        this.setState({ restaurant_schedule: null, fetchingData: false },
          () => {
            if (callback != null) {
              callback();
            }
          });
      }
    }).catch(e => this.setState({ restaurant_schedule: null, fetchingData: false }));
  }

  componentWillUnmount() {
    if (this.isClosedInterval) {
      clearInterval(this.isClosedInterval); // Make function to check if restaurant is open stop repeating every minute
    }
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState(prevState => ({
      isMobile: window.innerWidth <= 672,
      showCart: window.innerWidth > 672 || (prevState.showCart && prevState.isMobile)
    }));
  }
  addToOrder = (dish, category, position = null) => {
    let dishWithSelections
    if (dish.quantity != null) {
      dishWithSelections = {
        ...dish,
        category: category,
        quantity: dish.quantity
      };
    } else {
      dishWithSelections = {
        ...dish,
        category: category,
        quantity: 1
      };
    }
    this.setState((prevState) => {
      let order = prevState.order
      const dishInOrder = order.findIndex(d => d.id == dish.id && isEqual(d.selectedCondiments, dish.selectedCondiments) && isEqual(d.selectedExtras, dish.selectedExtras) && isEqual(d.category, category))
      if (dishInOrder != -1) { // dish is already in order, so just add to the quantity
        order[dishInOrder].quantity += dishWithSelections.quantity
      } else {
        if (position != null && position != -1) { // if needs to be inserted in a specific index
          order.splice(position, 0, dishWithSelections);
        } else { // just insert at the begginning
          order = [dishWithSelections, ...order]
        }
      }
      const storageKey = "restaurant_order_" + this.pageId
      const now = moment()
      CustomStorage.setItem(storageKey, JSON.stringify({order: order, updated_at: now}))
      return { order: order, }
    });
  }

  removeFromOrder = (index, quantity = 1) => {
    this.setState(prevState => {
      let newOrder = prevState.order
      if (prevState.order[index].quantity > quantity) {
        newOrder[index].quantity = prevState.order[index].quantity - quantity
      } else {
        newOrder = prevState.order.filter((_, i) => i !== index)
      }
      const storageKey = "restaurant_order_" + this.pageId
      const now = moment()
      CustomStorage.setItem(storageKey, JSON.stringify({order: newOrder, updated_at: now}))
      if (newOrder.length === 0 && prevState.isMobile) {
        return { order: newOrder, showCart: false }
      } else {
        return { order: newOrder }
      }
    });
  }

  confirmOrderModal = () => {
    if (this.props.isGuest && !this.state.phoneValidated) {
      this.setState({closePhoneModal: false})
    }else{
      let waitingTime = this.state.waitingTime
      if (!this.state.recentlyFetchedWaitingTime) {
        var url ='food_order/' + this.pageId + '/prep_time';
        if (this.props.isGuest) {
          url =`/${this.props.buildingName}/resident/guest/ws/2/food_order/${this.pageId}/prep_time`;
        }
        // fetch waiting time
        SmartRequests.get(url).then(resp => {
          if (resp.status === 200) {
            waitingTime = resp.data.prep_time
            this.setState({ recentlyFetchedWaitingTime: true, waitingTime: waitingTime });
            setTimeout(() => { // only fetch once every 5 minutes
              this.setState({ recentlyFetchedWaitingTime: false })
            }, 300000)
          }
        })
      }
      this.setState({ confirmOrderModalVisible: true });
    }
  }

  handleCategoryClick = (category) => {
    if (this.categoryRefs[category] && this.categoryRefs[category].current) {
      this.categoryRefs[category].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }

  retryWithNewPhone = () => {
    this.setState({ smsSent: false})
  }

  onSmsSent = (guestPhone, client_secret, guestName, guestUnit) => {
    this.setState({ smsSent: true,  guestPhone: guestPhone, guestName: guestName, guestUnit: guestUnit})
    this.client_secret = client_secret
    let guestData = {
      validated: false,
      guestPhone: this.state.guestPhone,
      guestName: this.state.guestName,
      guestUnit: this.state.guestUnit,
      client_secret: client_secret
    }
    CustomStorage.setItem('guest_data', JSON.stringify(guestData)).catch(e => console.error(e))
  }

  phoneValidationSucces = () => {
    this.setState({phoneValidated: true})
    let guestData = {
      validated: true,
      guestPhone: this.state.guestPhone,
      guestName: this.state.guestName,
      guestUnit: this.state.guestUnit,
      client_secret: this.client_secret
    }
    CustomStorage.setItem('guest_data', JSON.stringify(guestData)).catch(e => console.error(e))
    this.props.setupGuestMQTT(this.state.guestName, this.client_secret)
    this.handleMenuUpdate()
  }
  
  forgetPhone = () => {
    SmartRequests.Delete(`/${this.props.buildingName}/resident/guest/ws/2/guest_sms/${this.pageId}/${this.state.guestPhone}`).then( (resp) => {
      if (resp.status === 200) {
        this.setState({phoneValidated: false, 
          smsSent: false, // phase 1 of sms validation
          closePhoneModal: false,
          confirmOrderModalVisible: false
        })
        CustomStorage.removeItem('guest_data').catch(e => console.error(e))
        this.props.methods.disconnectMQTT()
      }
    }).catch(e => console.error(e))
  }

  handleTagClick = (tag) => {
    //remove if is already in the array
    this.setState(prevState => {
      let newSelectedTags = prevState.selectedTags.slice()
      if (newSelectedTags.includes(tag)) {
        newSelectedTags = newSelectedTags.filter(t => t !== tag)
      } else {
        newSelectedTags.push(tag)
      }
      if(newSelectedTags.length != 0){
        this.filterDishes(newSelectedTags)
      }
      return {selectedTags: newSelectedTags}
    })
  }

  modifyModal = (index, firstTime, dishBeingModified) => {
    this.setState({ modifyModalVisible: true, modifyModalIndex: index, firstTimeOpeningModal: firstTime, dishBeingModified: dishBeingModified });
  }

  handleModifyOk = (selectedCondiments, selectedExtras, quantity) => {
    if (this.props.isGuest && !this.state.phoneValidated) {
      this.setState({closePhoneModal: false})
    } else {
      const prevDish = this.state.dishBeingModified
      const modifyModalIndex = this.state.modifyModalIndex
      // Sort all selections for comparison
      const defaultLang = this.defaultLang
      for (const [key, value] of Object.entries(selectedCondiments)) {
        value.selected.sort((a, b) => a[defaultLang] > b[defaultLang]) // Sort all selections for comparison
      }
      selectedExtras = selectedExtras.sort((a, b) => a.name[defaultLang] > b.name[defaultLang]) // Sort extras for comparison

      // If dish already in order, delete it
      if (modifyModalIndex != null && modifyModalIndex != -1) {
        this.removeFromOrder(this.state.modifyModalIndex, prevDish.quantity)
      }
      // Add new version of modified dish
      this.addToOrder({ ...prevDish, selectedCondiments: selectedCondiments, selectedExtras: selectedExtras, quantity: quantity }, prevDish.category, modifyModalIndex)

      this.setState({
        modifyModalVisible: false,
        modifyModalIndex: null,
        firstTimeOpeningModal: null
      })
    }
  }

  handleModifyCancel = () => {
    this.setState({ modifyModalVisible: false, modifyModalIndex: null, dishBeingModified: null })
  }

  calculateTotalPrice = () => {
    return this.state.order.reduce((sum, dish) => {
      const extrasPrice = dish.selectedExtras.reduce((extrasSum, extra) => {
        return extrasSum + (extra ? extra.price : 0);
      }, 0);
      return sum + dish.quantity * (dish.price + extrasPrice);
    }, 0).toFixed(2);
  }
  myOrdersPath = () => {
    let myOrdersPath= '';
    if (this.props.isGuest) {
      myOrdersPath = `/${this.props.reqData.building.name}/guest/restaurant/page/${this.pageId}/myorders`
    }else{
      myOrdersPath = `/${this.props.reqData.building.name}/${this.props.unit}/dashboard/${this.state.restaurant_name}/myorders`
    }
    return myOrdersPath
  }

  confirmOrder = async (receive_selected, notes, delivery_place = null) => {
    const order = this.state.order;
    let orderToSend = order.map((dish) => {
      let cleanSelectedCondiments = {};
      Object.keys(dish.selectedCondiments).forEach((key) => {
        if (dish.selectedCondiments[key].selected.length > 0) {
          cleanSelectedCondiments[key] = dish.selectedCondiments[key]
        }
      })
      return { name: dish.name, id: dish.id, price: dish.price, quantity: dish.quantity, selectedCondiments: cleanSelectedCondiments, selectedExtras: dish.selectedExtras, category: dish.category }
    })
    let url = 'food_order/' + this.pageId + '/order';
    let params = {
      order: JSON.stringify(orderToSend),
      menu_id: this.state.menu_id, receive_method: receive_selected,
      notes: notes, delivery_place: delivery_place
    }
    if (this.props.isGuest) {
      url = `/${this.props.buildingName}/resident/guest/ws/2/food_order/${this.pageId}/${this.state.guestPhone}/order`;
      params.client_secret = this.client_secret
    }
    return SmartRequests.post(url,
      params
    ).then(resp => {
      const storageKey = "restaurant_order_" + this.pageId
      const now = moment()
      CustomStorage.setItem(storageKey, JSON.stringify({ order: [], updated_at: now }))
      this.setState({ confirmOrderModalVisible: false, order: [], showCart: !this.state.isMobile}); // Closes modal and cleans cart, hides cart if mobile so orders are visible 
      this.props.history.push({ pathname:  this.myOrdersPath()}) // Redirect to myOrders page
    }).catch(e=>{ 
      console.log('Error creating order', e)
      throw new Error(this.translate(msg.errorCreatingOrder))
    })
  }

  // Handles reload of dishes when new mqtt message for menu update is received
  handleMenuUpdate = () => {
    // confirm message recieved to parent to turn newMqttMessage to false
    this.props.methods.pageMessageReceivedCallback()

    // get old menu
    const oldMenu = this.state.dishes

    // get updated dishes
    this.getDishes(() => {

      this.isClosed()

      let menuChanged = false;
      const orderChanged = this.orderChanged()


      // Alert message for when menu is changed for current language
      let currentMenuChangedAlertDescription = this.translate(msg.menuChangedAlertDescription, { orderChanged: orderChanged })

      // Check if menu changed
      if (orderChanged || !isEqual(oldMenu, this.state.dishes)) {
        menuChanged = true
      }

      if (orderChanged) {
        // open order changed modal
        this.setState({ orderChangedModalVisible: true })
      } else {
        // show alert
        this.setState({ menuChangedAlertVisible: menuChanged, alertMessage: this.translate(msg.menuChangedAlertMessage), alertDescription: currentMenuChangedAlertDescription })
      }

    })
  }

  orderChanged = () => {
    // look for differences in dishes from order after menu update
    let dishesNotAvailable = []
    let dishesNameChanged = []
    let dishesDescriptionChanged = []
    let dishesPriceChanged = []
    let dishesOptionsChanged = []

    this.state.order.forEach((dishInOrder) => {
      const updatedDish = this.state.dishes.reduce((acc, dish) => {
        const dishFound = dish.category_plates.find(plate => plate.id === dishInOrder.id)
        return dishFound ? dishFound : acc
      }, null)
      // Need to let dishes know what to update/show
      if (updatedDish == null) {
        dishesNotAvailable.push(dishInOrder.id)
      } else {
        if (!isEqual(updatedDish.name, dishInOrder.name)) {
          dishesNameChanged.push(dishInOrder.id)
        }
        if (!isEqual(updatedDish.description, dishInOrder.description)) {
          dishesDescriptionChanged.push(dishInOrder.id)
        }
        if (!isEqual(updatedDish.price, dishInOrder.price)) {
          dishesPriceChanged.push(dishInOrder.id)
        }
        if (!isEqual(updatedDish.extras, dishInOrder.extras) || !isEqual(updatedDish.option_groups, dishInOrder.option_groups)) {
          dishesOptionsChanged.push(dishInOrder.id)
        }
      }
    })

    // If anything changed in the order, this is true
    const orderChanged = dishesNotAvailable.length > 0 || dishesNameChanged.length > 0 || dishesDescriptionChanged.length > 0 || dishesPriceChanged.length > 0 || dishesOptionsChanged.length > 0

    return orderChanged
  }

  // Handles schedule updates. Gets called by mqtt message
  handleScheduleUpdate = () => {
    // confirm message recieved to parent to turn newMqttMessage to false
    this.props.methods.pageMessageReceivedCallback()

    // get updated schedule
    this.getSchedule(this.isClosed)
  }

  // Function that gets called from OrderChangedModal on accept
  handleAcceptOrderChanges = (newOrder) => {
    const storageKey = "restaurant_order_" + this.pageId
    const now = moment()
    CustomStorage.setItem(storageKey, JSON.stringify({order: newOrder, updated_at: now}))
    this.setState({ orderChangedModalVisible: false, order: newOrder })
  }


  // Functions for dishVariantsModal

  openDishVariantsModal = (dish) => {
    this.setState({ dishVariantsModalVisible: true, dishVariant: dish })
  }

  // -------------------------------------------------------------------------

  delayModalClose = (callBack, ...args) => {
    this.setState({ modalClosing: true })
    setTimeout(() => {
      callBack(...args)
      this.setState({ modalClosing: false })
    }, 200)
  }

  handleOrderIsOldModalClose = (closeMethod) => {
    let order = [];
    const now = moment();
    const storageKey = "restaurant_order_" + this.pageId
    if (closeMethod === "confirm") {
      CustomStorage.getItem(storageKey).then(data => {
        order = JSON.parse(data).order        
        this.setState({order: order, orderIsOld: false, oldOrderDate: now, orderChangedModalVisible: false}, () => {
          const orderChanged = this.orderChanged()
  
          if (orderChanged) {
            // open order changed modal
            this.setState({ orderChangedModalVisible: true })
          } else {
            CustomStorage.setItem(storageKey, JSON.stringify({order: order, updated_at: now}))
          }
        })
      })
    } else {
      this.setState({orderIsOld: false, oldOrderDate: now, orderChangedModalVisible: false})
      CustomStorage.setItem(storageKey, JSON.stringify({order: order, updated_at: now}))
    }
  }

  render() {

    // TODO: get locale from local storage. First need to update local storage when changing language
    const currentLang = this.props.intl.locale // Gets selected language from props

    if (this.state.fetchingData && this.props.isGuest) {
      return <div style={{position: 'fixed', top: 0, bottom: 0, right: 0, left: 0, 'zIndex': 101}}> <Fetching enabled /> </div>
    }

    const totalPrice = this.calculateTotalPrice();

    var showMenu = []
    if(this.state.selectedTags.length !== 0){
      showMenu = this.state.filteredDishes
    }else{
      showMenu = this.state.dishes
    }

    const oldOrderDate = moment(this.state.oldOrderDate).locale(currentLang)

    return (
      <div>
        {/* <meta name="viewport" content="width=device-width, initial-scale=1"/> */}
        <Spin spinning={this.state.fetchingData} delay={10}>
          <Layout className='food-order-layout'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Title level={3} style={{ margin: '16px 24px 16px 20px' }}>{this.state.restaurant_title}</Title>
              <Button className='my-orders-button' type='primary' onClick={() => this.props.history.push({ pathname: this.myOrdersPath() })}>
                {this.translate(msg.myOrdersButton)}
              </Button>
            </div>
            <MenuHeader 
              dishes={showMenu}
              defaultLang={this.defaultLang}
              currentLang={currentLang}
              handleTagClick={this.handleTagClick}
              selectedTags={this.state.selectedTags}
              handleCategoryClick={this.handleCategoryClick}
              tagsToShow={this.state.tagsToShow}
            />
            <Layout>
              <Layout>
                <Content
                  style={{
                    padding: 24,
                    paddingTop: 0,
                    margin: 0,
                    // minHeight: 200,
                    backgroundColor: '#f0f2f5'
                  }}
                >
                  {this.state.dishes.length==0 ? (
                    <>
                      <br />
                      <Title level={1} disabled>
                        {this.translate(msg.emptyMenu)}
                      </Title>
                    </>
                  ) : (
                  <MenuComponent
                    dishes={showMenu}
                    categoryRefs={this.categoryRefs}
                    addToOrder={this.addToOrder}
                    removeFromOrder={this.removeFromOrder}
                    modifyModal={this.modifyModal}
                    openDishVariantsModal={this.openDishVariantsModal}
                    order={this.state.order}
                    isMobile={this.state.isMobile}
                    defaultLang={this.defaultLang}
                    currentLang={currentLang}
                  />
                  )}
                </Content>
              </Layout>

              {/* Sticky cart */}
              <Sider width={this.state.isMobile ? '100%' : 350} 
              collapsed={window.innerWidth < 1600 && !this.state.showCart} 
              collapsedWidth={this.state.isMobile ? 0 : 50} 
              style={this.state.isMobile ? 
              { backgroundColor: '#fff', position: 'fixed', top: 0, paddingTop: 'env(safe-area-inset-top)',bottom: 0, paddingBottom: 'env(safe-area-inset-bottom)',right: 0, zIndex: 100, width: '100%'} : 
              { backgroundColor: '#fff', overflowY: 'auto', zIndex: 100 }}>
                {this.state.showCart && (
                  <>
                    {this.state.isMobile && (
                      <div className='page-header'>
                        <div className='top'>
                          <div name='hide_cart_button' className="back-link" onClick={() => this.setState({ showCart: false })}><Icon className="icon" type="arrow-left" /></div> 
                        </div>
                      </div>
                    )}
                    <div style={{ padding:  this.state.isMobile? '4rem 20px 20px 20px' : '0 20px 20px 20px' }}>
                      {/* Cart header */}
                      <div className='cart-header'>
                        {/* {!this.state.isMobile && window.innerWidth < 1600 && (
                          <Button name='hide_cart_button' style={{ fontSize: '20px', marginLeft: '-15px', marginBottom: '15px' }} size="large" type='link' onClick={() => this.setState({ showCart: false })}><DoubleRightOutlined /></Button>
                        )} */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',borderBottom: '2px dashed #e8e8e8' }}>
                          <Title level={4} style={{ margin: 0 }}>{this.translate(msg.cartTitle)}</Title>
                          <div>
                            <Badge count={this.state.order.reduce((n, { quantity }) => n + quantity, 0)}>
                              <ShoppingCartOutlined style={{ fontSize: '24px' }} />
                            </Badge>
                          </div>
                        </div>
                      </div>
                      {/* List of products in cart */}
                      <List
                        dataSource={this.state.order}
                        style={this.state.isMobile ? 
                          { overflowY: 'auto', overflowX: 'hidden', maxHeight: '80vh', overscrollBehavior: 'contain' }
                          :
                          { overflowY: 'auto', overflowX: 'hidden', bottom: 0, top: 0, position: 'sticky' }
                        }
                        renderItem={(dish, index) => {
                          const modifyable = (dish.extras != null && dish.extras.length > 0) || (dish.option_groups != null && dish.option_groups.length > 0)
                          return (
                            <CartItem
                              name={dish.name}
                              price={dish.price}
                              quantity={dish.quantity}
                              selectedCondiments={dish.selectedCondiments}
                              selectedExtras={dish.selectedExtras}
                              currentLang={currentLang}
                              defaultLang={this.defaultLang}
                              removeFromOrder={() => this.removeFromOrder(index)}
                              addToOrder={() => this.addToOrder({ ...dish, quantity: 1 }, dish.category)}
                              modifyModal={() => this.modifyModal(index, false, dish)}
                              modifyable={modifyable}
                              editLabel={this.translate(msg.edit)}
                            />
                          )
                        }}
                      />
                      {/* Cart footer */}
                      </div>
                      {
                        !this.state.isMobile && this.state.order.length > 0 && (
                          <Footer style={{ backgroundColor: '#fff', position: '-webkit-sticky', position: 'sticky',   padding: '20px',  bottom: 0, zIndex: 100, display: 'flex', justifyContent: 'space-between', alignItems: 'center'  }}>
                            <Text strong>{this.translate(msg.total, { total: totalPrice })}</Text>
                            <Button
                              name='confirm_order_button'
                            type={this.state.order.length === 0 ? "default" : "primary"}
                              disabled={this.state.order.length === 0 || this.state.isClosed}
                              onClick={this.confirmOrderModal}
                            >
                              {this.translate(msg.confirm)}
                            </Button>
                          </Footer> 
                        )
                      }
                    </>
                )}
                {/* {!this.state.showCart && (
                  <Button name='show_cart_button' size="large" type='link' style={{ marginTop: '15px', fontSize: '20px' }} onClick={() => this.setState({ showCart: true })}><DoubleLeftOutlined /></Button>
                )} */}
              </Sider>
            </Layout>
            {this.state.isMobile && this.state.order.length > 0 && (
              <Footer style={{ backgroundColor: '#fff', borderTop: '1px solid grey', padding: '19px', position: 'fixed', bottom: 'env(safe-area-inset-bottom)', zIndex: 100, width:'100%' }}>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text strong style={{ fontSize: '1.8rem' }}>{this.translate(msg.total, { total: totalPrice })}</Text>
                  {this.state.showCart ?
                    (<Button
                      name='confirm_order_button'
                      type={this.state.order.length === 0 ? "default" : "primary"}
                      key='confirm'
                      disabled={this.state.order.length === 0 || this.state.isClosed}
                      onClick={this.confirmOrderModal}
                    >
                      {this.translate(msg.confirm)}
                    </Button>)
                    :
                    (
                    <div>
                      <Badge
                        count={this.state.order.reduce((n, { quantity }) => n + quantity, 0)}
                        overflowCount={99} // Optional: limits the display count
                      >
                        <Button
                          name='show_cart_button'
                          key="open_cart"
                          type="primary"
                          onClick={() => this.setState({ showCart: true })}
                          style={{ position: 'relative' }}
                        >
                          <ShoppingCartOutlined style={{ fontSize: '18px' }} />
                          {this.translate(msg.showCart)}
                        </Button>
                      </Badge>
                    </div>
                    )}
                </div>
              </Footer>
            )}
            {this.state.modifyModalVisible && this.state.modifyModalIndex !== null && !(this.props.isGuest && !this.state.phoneValidated && !this.state.closePhoneModal) && (
              <ModifyModal
                visible={!this.state.modalClosing}
                firstTime={this.state.firstTimeOpeningModal}
                cancelAddToOrder={(...args) => this.delayModalClose(this.handleModifyCancel, ...args)}
                removeFromOrder={this.removeFromOrder}
                onCancel={(...args) => this.delayModalClose(() => this.setState({ modifyModalVisible: false, modifyModalIndex: null, dishBeingModified: null }), ...args)}
                onOk={(...args) => this.delayModalClose(this.handleModifyOk, ...args)}
                dish={this.state.dishBeingModified}
                dishIndex={this.state.modifyModalIndex}
                defaultLang={this.defaultLang}
                currentLang={currentLang}
                isMobile={this.state.isMobile}
              />
            )}
            {this.state.dishVariantsModalVisible && this.state.dishVariant !== null && (
              <DishVariantsModal
                visible={!this.state.modalClosing}
                removeFromOrder={this.removeFromOrder}
                addToOrder={this.addToOrder}
                closeModal={(...args) => this.delayModalClose(() => this.setState({ dishVariantsModalVisible: false, dishVariant: null }), ...args)}
                modifyModal={(...args) => this.delayModalClose(this.modifyModal, ...args)}
                order={this.state.order}
                exampleDish={this.state.dishVariant}
                defaultLang={this.defaultLang}
                currentLang={currentLang}
                translate={this.translate}
                isMobile={this.state.isMobile}
              />
            )}
            {this.state.confirmOrderModalVisible && !this.state.orderChangedModalVisible && !this.state.isClosed && (
              <ConfirmOrderModal
                level={this.props.level}
                unit={this.props.unit}
                visible={!this.state.modalClosing}
                onCancel={(...args) => this.delayModalClose(() => this.setState({ confirmOrderModalVisible: false }), ...args)}
                onOk={(receive_selected, notes, delivery_place) => this.confirmOrder(receive_selected, notes, delivery_place)}
                order={this.state.order}
                defaultLang={this.defaultLang}
                currentLang={currentLang}
                totalPrice={totalPrice}
                openTime={this.state.openTime}
                closeTime={this.state.closeTime}
                optional_states={this.state.optional_states}
                deliver_places={this.state.deliver_places}
                restaurant_title={this.state.restaurant_title}
                waitingTime={this.state.waitingTime}
                reqData={this.props.reqData}
                pageId={this.pageId}
                isMobile={this.state.isMobile}
                building={this.props.reqData.building}
                isGuest={this.props.isGuest}
                forgetPhone={this.forgetPhone}
                phoneValidated={this.state.phoneValidated}
                guestPhone={this.state.guestPhone}
                guestUnit={this.state.guestUnit}
              />
            )}
            {this.state.orderChangedModalVisible && !this.state.modifyModalVisible && (
              <OrderChangedModal
                level={this.props.level}
                visible={this.state.orderChangedModalVisible}
                order={this.state.order}
                dishes={this.state.dishes}
                removeDishes={(dishesToRemove) => this.removeDishes(dishesToRemove)}
                currentLang={currentLang}
                defaultLang={this.defaultLang}
                onOk={(newOrder) => this.handleAcceptOrderChanges(newOrder)}
                modifyModal={this.modifyModal}
                removeFromOrder={this.removeFromOrder}
                isMobile={this.state.isMobile}
              />
            )}
            {this.state.dishes.length>0 && this.props.isGuest && !this.state.phoneValidated && !this.state.closePhoneModal &&
              <PhoneModalValidation
                visible={!this.state.phoneValidated}
                onCancel={() => this.setState({ closePhoneModal: true })}
                building={this.props.reqData.building}
                onSmsSent={this.onSmsSent}
                smsSent={this.state.smsSent}
                pageId={this.pageId}
                guestPhone={this.state.guestPhone}
                guestName={this.state.guestName}
                guestUnit={this.state.guestUnit}
                phoneValidationSucces={this.phoneValidationSucces}
                client_secret={this.client_secret}
                retryWithNewPhone={this.retryWithNewPhone}
                country={this.state.country}
                isMobile={this.state.isMobile}
                buildingName={this.props.buildingName}
              />
            }
            <div className='alert-container'>
              {/* Place alerts in order of priority, top to bottom. */}
                {(this.state.isClosed) && (
                  // Is closed
                  this.state.restaurant_schedule.close_indefinitely ? (
                    <Alert
                      message={this.translate(msg.restaurantClosedTitle, {restaurant_name: this.state.restaurant_title, all_day: "indefinitely"})}
                      description={(this.state.restaurant_schedule.close_indefinitely_msg) || this.translate(msg.restaurantClosedDescription, {all_day: "indefinitely", open_time: this.state.willOpenAt})}
                      type="error"
                      showIcon
                      className='alert'
                    />
                  ) : (
                    <Alert
                      message={this.translate(msg.restaurantClosedTitle, {restaurant_name: this.state.restaurant_title, all_day: this.state.allDay})}
                      description={this.translate(msg.restaurantClosedDescription, {all_day: this.state.allDay, open_time: this.state.willOpenAt})}
                      type="error"
                      showIcon
                      className='alert'
                    />
                  )
                )}
                {(this.state.willCloseIn >= 0 && this.state.willCloseIn <= 15) && (
                // Will close in less than 15 minutes
                  <Alert
                    message={this.translate(msg.closesSoonTitle, {restaurant_name: this.state.restaurant_title})}
                    description={this.translate(msg.closesSoonDescription, {minutes: this.state.willCloseIn})}
                    type="warning"
                    showIcon
                    className='alert'
                  />
                )}
                {(this.state.menuChangedAlertVisible) && (
                // Menu changed Alert
                  <Alert
                    message={this.state.alertMessage}
                    description={this.state.alertDescription}
                    type="warning"
                    showIcon
                    closable
                    className='alert'
                    onClose={() => this.setState({ menuChangedAlertVisible: false })}
                  />
                )}
                <Modal
                  visible={this.state.orderIsOld}
                  title={null} 
                  closable={false}
                  footer={null}
                  transitionName='ant-modal-slide-up'
                  className={'modal order-is-old-modal'}
                >
                  <div className='order-is-old-modal-container'>
                    <div className='order-is-old-modal-text-container'>
                      <Title level={3}>
                        {this.translate(msg.orderIsOldModalTitle)} {oldOrderDate.calendar()}
                      </Title>
                      <Text>
                        {this.translate(msg.orderIsOldModalDescription)}
                      </Text>
                    </div>
                    <div className='order-is-old-modal-buttons-container'>
                      <Button type="primary" onClick={() => this.handleOrderIsOldModalClose("confirm")}>
                        {this.translate(msg.confirm)}
                      </Button>
                      <Button type="danger" onClick={() => this.handleOrderIsOldModalClose("cancel")}>
                        {this.translate(msg.cancel)}
                      </Button>
                    </div>
                  </div>
                </Modal>
            </div>
          </Layout>
        </Spin>
        <TransitionSwitch level={this.props.level + 1}>
          <Route
            path={`/:buildingName/:unit/dashboard/:restaurantName/myOrders`}
            render={() => {
              return (<MyOrders
                loading={this.props.loading}
                level={this.props.level}
                name={'myorders'}
                pageId={this.pageId}
                subPages={this.props.subPages}
                unit={this.props.unit}
                reqData={this.props.reqData}
                parentPath={this.props.parentPath}
                data={this.props.data}
                methods={this.props.methods}
                initialData={this.props.initialData}
                url={this.props.url}
                defaultLang={this.defaultLang}
                currentLang={currentLang}
                isGuest={false}
                buildingName={this.props.buildingName}
                guestPhone={null}
              />)
            }} />
            <Route
              path={`/:buildingName/guest/restaurant/page/:pageId/myOrders`}
              render={({ match }) => {
                return (
                  <MyOrders
                    loading={this.props.loading}
                    level={this.props.level}
                    name="myorders"
                    pageId={this.pageId}
                    subPages={this.props.subPages}
                    unit="guest"
                    reqData={this.props.reqData}
                    parentPath={this.props.parentPath}
                    data={this.props.data}
                    methods={this.props.methods}
                    initialData={this.props.initialData}
                    url={this.props.url}
                    defaultLang={this.defaultLang}
                    currentLang={this.currentLang}
                    isGuest={true}
                    guestPhone={this.state.guestPhone}
                    client_secret={this.client_secret}
                    subscribeToGuestChannel={this.props.methods.subscribeToGuestChannel}
                    buildingName={this.props.buildingName}
                  />
                );
              }}
            />
        </TransitionSwitch>
      </div>
    );
  }
}

export default injectIntl(withRouter(FoodOrder));