import React, { Component } from 'react';
import MDRenderer from '../../MDRenderer';
import { dateHelpers, reservations, DAY_OF_WEEK } from './helpers';
import { RESERVED_FOR_ME, RESERVED_FOR_NOBODY, RESERVED_FOR_ANOTHER, RESERVED_FOR_CLOSURE } from './helpers';

import LoadingComponent from '../../../components/LoadingComponent';
import SmartLink from '../../../components/SmartLink';

import { Calendar as AntCalendar, message } from 'antd';
import * as moment from 'moment';

import { injectIntl } from "react-intl";

import msg from './messages'

function maxString(array) {
  return array.reduce((a, b) => a > b ? a : b);
}

export class Calendar extends Component {
  constructor(props) {
    super(props);

    this.getAvailability = this.getAvailability.bind(this);
    this.isDateLoading = this.isDateLoading.bind(this);
    this.isByHour = (this.props.groupData.period !== 0)
    let NOW = new Date();
    //let current_month = dateHelpers.getMonthDate(NOW+.getFullYear(), NOW.getMonth());
    let current_month = moment();
    
    let minTimeInAdvance = this.props.groupData.min_time_in_advance;// !this.isByHour means its a daily reservable group, they have by default they have 1day/24hs in advance for reservations
    let openDays = '';
    let counterOpenDays = 0;
    DAY_OF_WEEK.forEach(( day ) => {
      if (this.props.groupData['dow_'+day] === 1) { 
        openDays += this.props.intl.formatMessage(msg[day]) + ', '
        counterOpenDays++;
      }
    })
    if (openDays.endsWith(', ')) {
      openDays = openDays.slice(0, -2); 
    }
    if (counterOpenDays === 7) {
      this.amenityRestrictionsTitle = this.props.intl.formatMessage(msg.allDaysOpen)
    }else{
      this.amenityRestrictionsTitle = this.props.intl.formatMessage(msg.openDays, {
        openDays: openDays
      })
    }
    if (minTimeInAdvance!==0 && this.props.groupData.min_advance_unit === 'days') {
      this.amenityRestrictionsTitle += this.props.intl.formatMessage(msg.canReserveDays, {
        minDays: minTimeInAdvance
      })
    } else {
      let minHours = minTimeInAdvance
      if (minTimeInAdvance===0) {
        minHours = 1
      }
      this.amenityRestrictionsTitle += this.props.intl.formatMessage(msg.canReserveHours, {
        minHours: minHours
      })
    }
    var validRange = [
      moment().add(
        minTimeInAdvance, 
        this.props.groupData.min_advance_unit
      ),
      moment().add(
        this.props.groupData.max_days_in_advance? this.props.groupData.max_days_in_advance: 2, 
        this.props.groupData.max_days_in_advance? 'days': 'years'
      )
    ];

    const isStaffOnly = (this.props.groupData.staff_only === 1);

    this.state = {
      'blocked': [],
      'selected': null,
      'current_month': current_month,
      'focusedDay': null,
      'validRange': validRange,
      'isStaffOnly': isStaffOnly,
      'loadingDates': {},
    };
  }

  getLastTurnOfDate(date) {
    var dateIndex = dateHelpers.serializeDay(date);
    var lastTurn = null;

    if (this.props.availability && this.props.availability[dateIndex]) {
      var hoursOfDay = Object.keys(this.props.availability[dateIndex]);
      if (hoursOfDay.length !== 0) {
        let hour = maxString(hoursOfDay);
        lastTurn = dateHelpers.setHour(date, hour);
      }
    } else {
      // console.log('[Calendar:getLastTurnOfDate()]! Availability is not valid for date ('+dateIndex+')');
    }
    return lastTurn;
  }

  disabledDate(date) {
    if (dateHelpers.isBlockedDOW(this.props.groupData, date.day())) {
      return true;
    }
    if (this.isByHour) {
      let lastTurn = this.getLastTurnOfDate(date);
      if (!lastTurn || lastTurn.isBefore(moment())) {
        return true;
      }
    } else {
      if (date.isBefore(moment())) {
        return true;
      }
    }
    return false;
  }

  getDefaultValue() {
    var defaultValue = this.props.defaultValue || moment().add(
      this.props.groupData.min_time_in_advance,
      this.props.groupData.min_advance_unit
    );
    return defaultValue;
  }

  requestFocus(dayIndex) {
    var newState = {};
    if (this.state.focusedDay === dayIndex) 
      newState['focusedDay'] = null
    else {
      newState['focusedDay'] = dayIndex;
      console.log('[amenities:Calendar.requestFocus()] Selected day is now ' + dayIndex);
    }
    this.setState(newState);
  }

  nextMonth() {
    console.log('[amenities:Calendar.nextMonth()] Next month button pressed');
    this.setState(function(state, props) {
      var newMonth = new Date(Number(state.current_month));
      newMonth.setMonth(newMonth.getMonth()+1);
      var newState = {
        'current_month': newMonth
      };
      return newState;
    });
  }

  prevMonth() {
    console.log('[amenities:Calendar.prevMonth()] Prev month button pressed');
    this.setState(function(state, props) {
      var newMonth = new Date(Number(state.current_month));
      newMonth.setMonth(newMonth.getMonth()-1);
      var newState = {
        'current_month': newMonth
      };
      return newState;
    });
  }

  getCalendarData() {
    return dateHelpers.getCalendarRepresentation(this.state.current_month);
  }

  isDateClosured(date) {
    var isClosured = false;
    if (this.props.groupData.period === 0) {
      for (var i = 0; i < this.props.closures.length && !isClosured; i++) {
        var closure = this.props.closures[i];
        var closureDate = moment(closure.start_date);
        isClosured = (dateHelpers.isSameDate(closureDate, date));
      }
    } 
    return isClosured;
  }

  getAvailability(date) {
    var dayIndex = dateHelpers.serializeDay(date);
    var hourIndexes;
    var availability = RESERVED_FOR_ANOTHER;
    if (this.props.availability && this.props.availability[dayIndex]) {
      if (this.isByHour) {
        hourIndexes = Object.keys(this.props.availability[dayIndex]);
      } else {
        hourIndexes = ['0000'];
      }
      for (var h in hourIndexes) {
        var hourIndex = hourIndexes[h];
        var availabilitySlot = this.props.availability[dayIndex][hourIndex]
        for (var itemID in availabilitySlot) {
          if (availabilitySlot[itemID] === RESERVED_FOR_ME) {
            // console.log('## TRACE ## found reservation for me');
            availability = RESERVED_FOR_ME;
            break;
          } else if (availabilitySlot[itemID] === RESERVED_FOR_NOBODY) {
            // console.log('## TRACE ## found reservation for nobody');
            availability = RESERVED_FOR_NOBODY;
          }
        }
        if (availability === RESERVED_FOR_ME) 
          break; // i know it's not a good practice, but... :b
      }
    } else {
      availability = RESERVED_FOR_NOBODY;
      // console.log('## TRACE ## availability ain\'t ready yet for day '+dayIndex);
    }
    // console.log('## TRACE ##! reservation level for day '+date.format('YYYYMMDDHHmm')+' is '+availability);
    return availability;
  }

  dateSelected(date) {
    
    console.log('[amenities:Calendar.dateSelected()] Selected day: ' + date.format('DD-MM-YYYY'));

    const isStaffOnly = (this.props.groupData.staff_only === 1);

    if (!isStaffOnly && (!this.isDateClosured.bind(this)(date) && !this.isDateLoading(date))) {
      
      var reservationLevel = this.getAvailability(date);

      var calendarEvent = {
        'date': date,
      };

      // console.log('reservationLevel: '+reservationLevel+', period: '+this.props.groupData.period);
      if (reservationLevel === RESERVED_FOR_NOBODY ) {
        calendarEvent['type'] = 'date';
      } else if (reservationLevel === RESERVED_FOR_ME && this.props.groupData.period === 0) {
        calendarEvent['type'] = 'cancel';
      }

      this.props.onSelect(calendarEvent);
    } else {
      console.log('[amenities:Calendar.dateSelected()] Ignoring click because date is closured or loading');
    }
  }

  refreshLoadingDates(prevProps) {
    if (prevProps.myReservations !== this.props.myReservations) {
      this.setState({
        loadingDates: this.getLoadingDates(),
      });
    }
  }

  getLoadingDates() {
    let loadingDates = {};
    let i = 0;
    do {
      let iteratedReservation = this.props.myReservations[i];
      if (iteratedReservation) {
        let iteratedDate = moment(iteratedReservation.start_date);
        let isLoading = iteratedReservation.state !== 'reserved';
        if (isLoading) {
          let dateIndex = dateHelpers.serializeDay(iteratedDate);
          loadingDates[dateIndex] = true;
        }
        i++;
      }
    } while (i < this.props.myReservations.length);
    return loadingDates;
  }

  getCellStyle(date) {
    if (this.isDateLoading(date)) {
      return 'loading';
    }
    return reservations.getStyledClass(this.getAvailability(date));
  }

  getCellComponent(date) {
    const t = this.props.intl.formatMessage
    let isDateLoading = this.isDateLoading(date);
    let cellLink = this.props.groupData.staff_only || this.props.groupData.period === 0 || isDateLoading
    ? '.' // do nothing
    : dateHelpers.serializeDay(date) + '/';
    return (
      <div className={'date-slot ' + this.getCellStyle(date)}>
        <LoadingComponent isLoading={isDateLoading} />
        <SmartLink to={cellLink} level={this.props.level} onClick={(e) => {
          if(this.state.isStaffOnly) {
            message.warning(t(msg.contactFrontDesk));
            e.preventDefault();
          }}}
        >
          <span>{date.date()}</span>
        </SmartLink>
      </div>
    );
  }

  isDateLoading(date) {
    let dateIndex = dateHelpers.serializeDay(date);
    return this.state.loadingDates[dateIndex];
  }

  componentDidUpdate(prevProps) {
    this.refreshLoadingDates(prevProps);
  }

  render() {
    return (
      <>
      <div className="page-description">
            <MDRenderer className="text frame"> {this.amenityRestrictionsTitle} </MDRenderer>
        </div>
      <AntCalendar
        defaultValue={this.getDefaultValue.bind(this)()}
        value={this.getDefaultValue.bind(this)()}
        disabledDate={this.disabledDate.bind(this)}
        mode="month"
        validRange={this.state.validRange}
        onSelect={this.dateSelected.bind(this)}
        onPanelChange={this.props.onMonthChange}
        dateFullCellRender={this.getCellComponent.bind(this)}
      />
      </>
    );
   }
}

export default Calendar = injectIntl(Calendar)