import mqtt from 'mqtt'
import BuildingInfo from '../Main/buildingInfo'
import cookieStorage from '../Main/cookieStorage.js'
import { CustomStorage } from '../../utilities'
//import { * } from 'mqtt-pattern'
import { v4 as uuidv4 } from 'uuid';

var MQTTPattern = require('mqtt-pattern')

const TIMEOUT_30_SEC = 30 * 1000
const TIMEOUT_10_SEC = 10 * 1000
const TIMEOUT_5_SEC = 5 * 1000
var MQTT_BROKER =  `wss://${window.location.host}/mqtt`
if (window.location.protocol === "http:") {
   MQTT_BROKER = `ws://${window.location.host}/mqtt`
}
var instance = undefined
var subscriptions = [];// key: [handlers]
var onReconnectCallback, onErrorCallback, onConnectCallback
var stabilityCheck
var isConnectionStable = false


function syncSubscriptions() {
  if (instance) {
    for(const [tp, tpHandlers] of Object.entries(subscriptions)) {
      instance.subscribe(tp)
    }
  }
}

// internal message handler 
function onNewMessages(topic,message) {
  let matched = 0
  console.log("new message, current subs:", subscriptions)
  for(const [tp, tpHandlers] of Object.entries(subscriptions)) {
    console.log("Testing if ", topic, " matches subscribed partern ", tp)
    if (MQTTPattern.matches(tp,topic)) {
      tpHandlers.forEach( tpHandler => { 
          tpHandler(topic,message.toString())
          matched++
          })
      }
    }
  if (matched == 0) {
    console.warn("MQTT: message received but nobody was registered: topic:", topic, "msg: ", message)
  } else {
    console.log("MQTT: message handle by ", matched, " observers on topic: ", topic)
  }
}


async function getCredentials () {
  const username = await BuildingInfo.getEmail()
  let password = cookieStorage.getAuthCookie()
  if (!password) {
    password = `Bearer ${await CustomStorage.getItem('access_token')}`
  }
  const credentials = { username, password }
  return credentials
}

async function getNewMqttClient (config) {
  let client
  try {
    client = mqtt.connect(MQTT_BROKER, config)
    client.on('connect', async () => {
      client.stream.on('error', errorHandler)
      syncSubscriptions()
      runStabilityCheck()
      callOnConnectCallback()
    })
    client.on('error', errorHandler)
    client.stream.on('error', errorHandler)
    client.on('message', onNewMessages)
  } catch (err) {
    this.mqttErrorHandler(err)
  }
  return client
}

function runStabilityCheck () {
  stopStabilityCheck()
  stabilityCheck = setTimeout(() => {
    isConnectionStable = true
    console.log('MQTT conection is stable!')
  }, TIMEOUT_5_SEC)
}

function stopStabilityCheck () {
  if (stabilityCheck) {
    clearTimeout(stabilityCheck)
  }
  stabilityCheck = null
  isConnectionStable = false
}

// async function subscribeTopics (client) {
//   const buildingId = (await BuildingInfo.getCurrentBuilding()).id
//   const unit = await BuildingInfo.getCurrentUnit()
//   const prefix = buildingId + '/' + unit + '/+/service/'
//   console.log('CONNECTED TO ' + prefix)
//   MQTT_TOPICS.forEach(topic => {
//     console.log('Subscribing to ' + prefix + topic)
//     client.subscribe(prefix + topic)
//   })
// }

function errorHandler (error) {
  if (isConnectionStable) {
    // it's the first time connection fails since it was stable
    // will restart connection instantly
    setup()
  } else {
    callOnErrorCallback(error)
  }
  stopStabilityCheck()
}

function callOnReconnectCallback (...args) {
  if (typeof onReconnectCallback === 'function') {
    onReconnectCallback(...args)
  }
}

function callOnErrorCallback (...args) {
  if (typeof onErrorCallback === 'function') {
    onErrorCallback(...args)
  }
}

function callOnConnectCallback (...args) {
  if (typeof onConnectCallback === 'function') {
    onConnectCallback(...args)
  }
}

export function subscribeToTopic(topicPattern, onMessageHandler) {
  console.log("About to subscribe to topic", topicPattern);
  if (subscriptions[topicPattern]) {
    const existingHandlerIndex = subscriptions[topicPattern].findIndex(
      existingHandler => existingHandler.toString() === onMessageHandler.toString()
    );

    if (existingHandlerIndex !== -1) {
      console.warn("Replacing existing handler for topic", topicPattern);
      subscriptions[topicPattern][existingHandlerIndex] = onMessageHandler;
      return function() {
        return;
      };
    }
    console.warn("The topicPattern", topicPattern, "has more than one observer");
  } else {
    subscriptions[topicPattern] = [];
    if (instance) {
      instance.subscribe(topicPattern);
      console.log("Subscribed to topic", topicPattern);
    }
  }
  subscriptions[topicPattern].push(onMessageHandler);
  const removeSubscription = function() {
    return;
  };
  
  return removeSubscription;
}

export function isConnected () {
  return isConnectionStable
}

export async function setup (isGuest=null, guestUser=null, guestPassword=null) {
  let credentials;
  if (isGuest) {
    credentials = {
      username: guestUser,
      password: guestPassword,
      clientId: 'guest_' + uuidv4()
    }
  }else{
    credentials = await getCredentials()
  }
  const reconnectPeriod = TIMEOUT_30_SEC
  const keepalive = TIMEOUT_10_SEC
  const config = { ...credentials, reconnectPeriod, keepalive }
  const client = await getNewMqttClient(config)
  if (instance && typeof instance.end === 'function') {
    instance.end()
  }
  instance = client
}

export function publish(topic, message) {
  if (instance) {
    instance.publish(topic,message);
  } else {
    console.error("mqttClient:publish Not connected yet!")
  }
}

export function onReconnect (callback) {
  onReconnectCallback = callback
}

export function onError (callback) {
  onErrorCallback = callback
}

export function onConnect (callback) {
  onConnectCallback = callback
}

export function disconnect() {
  if (instance) {
    instance.end(true, () => {
      console.log("MQTT connection closed.");
    });
    instance = undefined;
    subscriptions = [];
    stopStabilityCheck();
  } else {
    console.warn("No active MQTT connection to disconnect.");
  }
}
