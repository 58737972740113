import { Icon, Modal } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { SmartRequests } from '../../../utilities/index.js';
import msg from './messages';
import { CaretRightFilled, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';


class Invitations extends React.Component  {
  
  constructor(props) {
    super(props);
      this.state = {
        isModalVisible: false,
        modalContent: null,
        guest_auth_list:[],
        events:[]
    }
    this.t= this.props.intl.formatMessage
  }


  componentDidMount() {
    console.log('mounting guest');
  }

  showModalEvents = (content) => {
    this.showAuths=false;
    SmartRequests.get('guest_authorization/'+this.props.infoGuest.id+'/events').then( resp => {
      if (resp.status === 200) {
          this.guests = resp.data;
          this.setState({
            isModalVisible: true,
            events: resp.data.events,
          });
      }
  }).catch( err => {
      console.log("Error: " + err);
  });
  };

  changeToEditGuestRoute = (type_of_edit, data_guest, id_guest) => { 
    this.props.history.push({
      pathname:`/${this.props.building_name}/${this.props.unit}/dashboard/guest_auth/edit-guest/${id_guest}`,
      state: {
        guest: data_guest,
        type_of_page: type_of_edit,
      },
    });
  }

  changeToEditAuthoRoute = (type_of_edit,data_guest, id_guest) => {
    this.props.history.push({
      pathname:`/${this.props.building_name}/${this.props.unit}/dashboard/guest_auth/edit-guest/${id_guest}/edit-auth`,
      state: {
        guest: data_guest,
        type_of_page: type_of_edit,
      },
    });
  }

  renderEvent = (event) => {
    let hasNotes = event.text;
    let typeOfEvent = event.event;
    return (
      <div>
        <div className='header-event'>
          <div className={`type-of-event ${typeOfEvent === "in" ? "check-in" : "check-out"}`}>
            { typeOfEvent === "in" 
              ? (
                <RightOutlined style={{ marginTop: '0', marginBottom: '1rem'}} />
              ) : (
                <LeftOutlined style={{ marginTop: '0', marginBottom: '1rem'}}/>
              )
            }
            <p>{this.t(msg[event.event])}</p>
          </div>
          <p className='vertical-bar'>{" | "}</p>
          <p>{event.fmt_date}</p>
          <p className='vertical-bar'>{" | "}</p>
          <p>{this.t(msg.staffAutho) + ": " + event.user_name}</p>
        </div>

        { hasNotes &&
          <div class="container-message-event">
            <h3 class="title-message-event">{this.t(msg.notesByStaff)}</h3>
            <p class="p-message-event">{event.text}</p>
          </div>
        }
    </div>
    )
  }
  handleCancelModal = () => {
    this.setState({
      isModalVisible: false,
      modalContent: null,
    });
  };


  deleteTimeFrameGuest = (guest_id, tf_id) => {
    tf_id= tf_id===null ? "" : tf_id
    SmartRequests.Delete('guest_authorization/' +guest_id+'/'+ tf_id).then( resp => {
      if (resp.status === 200) {
        this.setState(oldState => {
          const updatedGuestsAuth = oldState.guest_auth_list.slice().filter(el => el.timeframe_id !== tf_id);
          if (updatedGuestsAuth.length === 0 || tf_id===""){
            updatedGuestsAuth[0]={"auth_details":"Not allowed", "authorization_type":"not_allowed"};
          }
          return { guest_auth_list: updatedGuestsAuth };
        });
      }
  }).catch(err => {
      console.log("Error when delete")
  })
  }
  
  getEventsToRender = () => {
    const { events } = this.state;
    let eventsToRender  = [];
    const isFirstIn = events[0].event === "in";
    const indexStart = isFirstIn ? 1 : 2;
    
    const renderItems = (inEvent, outEvent) => (
      <div key={`item_${inEvent.id}`}>
        {inEvent && this.renderEvent(inEvent)}
        {outEvent && 
          <div>
            <hr class="event-hr"/>
            {this.renderEvent(outEvent)}
          </div>
        }
      </div>
    );

    if(isFirstIn){
        eventsToRender.push(renderItems(events[0], null));
    } else {
        eventsToRender.push(renderItems(events[1], events[0]));
    }

    for (let index = indexStart; index < events.length; index += 2) {
        const item1 = events[index];
        const item2 = index + 1 < events.length ? events[index + 1] : null;
        eventsToRender.push(renderItems(item2, item1));
    }

    return eventsToRender;
}

  render() {
    const g = this.props.infoGuest;

    const authsNotExpired =  g.guest_auth_list || [];
    const authoType = g.authorization_type;

    let eventsToRender = [];
    if (!this.showAuths && this.state.events.length !== 0) {
      eventsToRender = this.getEventsToRender();
    }

    return (
      <div className='invitations'>
        <div className='item-invitation'>
          <Modal
            title= {this.showAuths ? this.t(msg.editAuthos) : this.t(msg.showEvents)}
            visible={this.state.isModalVisible}
            onCancel={this.handleCancelModal}
            footer={null}
            className="invitations"
          >   
            {!this.showAuths && this.state.events.length !== 0 
              ? (
                eventsToRender.map((item, index)=> (
                  <div key={index} className='entries-details'>
                    {item}
                  </div>
                ))
              ) : (
                this.t(msg.noEvents)
              )}
          </Modal>

          <li style={{"position":"relative", "clear": "both"}} className='li-request' >
            <div className='container-header-guest'>
              <div className='header-guest'>
                <p className="title">{g.guest_name}</p> 
                <p>{`${this.t(msg.guest_identification)}: ${g.guest_identification}`}</p> 
              </div>
            
              <p className="container-details-guest">
                <a onClick={() => this.showModalEvents()}>
                  <Icon type="info-circle"/>&nbsp; {this.t(msg.showEvents)}
                </a>
              </p>
            </div>

            <div className='body-guest'>
              <h4>{this.t(msg.isAuthorized) + ": "}</h4>
              {
                authoType === "recurrence" 
                ? (
                  authsNotExpired.map((item, index) => (
                    <div key={index} className='item-auth-active'>
                      <CaretRightFilled style={{ marginTop: '0', marginBottom: '1rem'}} />
                      <p>{item.guest_auth_details}</p>
                    </div>
                  ))
                ) : (
                  <div>
                    <p>{this.t(msg[authoType])}</p>
                  </div>
                )
              }
             
            </div> 

            <div className='buttons-guest'>

              <Button
                type='default'
                onClick={() => {this.changeToEditGuestRoute("edit-guest", g, g.id)}}
              >
                {this.t(msg.editGuest)}
              </Button>

              <Button 
                type="primary" 
                onClick={() => {this.changeToEditAuthoRoute("edit-auth", g, g.id)}}
              >
                {this.t(msg.editAuthos)}
              </Button>
              
              <Button id="delete-guest" type="danger" onClick={() => this.props.deleteGuest(g.id)}>{this.t(msg.delete)}</Button>
            </div>
          </li>
        </div>
          
      </div>
    );
  }
}

export default injectIntl(withRouter(Invitations));
