import React from 'react';
import { Button, Typography, Divider } from 'antd';
import { MinusOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { PlusMinus } from '../../../components/PlusMinus.js';

const { Text } = Typography;


export const CartItem = ({ name, price, quantity, selectedCondiments, selectedExtras, currentLang, defaultLang, removeFromOrder, addToOrder, modifyable, modifyModal, editLabel }) => {
	const condiments = selectedCondiments ? Object.entries(selectedCondiments).filter(([key, value]) => value.selected.length > 0).map(([key, value]) => (
	  <div key={key}>
		<Text strong>{value.group_name[currentLang] || value.group_name[defaultLang]}: </Text>
		<Text>{value.selected.map(sel_name => sel_name[currentLang] || sel_name[defaultLang]).join(', ')}</Text>
	  </div>
	)) : null;
  
	const extras = selectedExtras.length > 0 ? (
	  <div>
		<Text strong>Extras: </Text>
		<Text>{selectedExtras.map(extra => extra.name[currentLang] || extra.name[defaultLang]).join(', ')}</Text>
	  </div>
	) : null;
  
	const extraPrice = selectedExtras.reduce((total, extra) => total + extra.price, 0);
  
	return (
	  <div name='cart_item' className='cart-item'>
		<div className='cart-item-title' style={{ display: 'flex', justifyContent: 'space-between' }}>
		  <Text name='cart_item_name' strong style={{ color: 'black', fontSize: '16px' }}>{name[currentLang] || name[defaultLang]}</Text>
		  <Text name='cart_item_price' type='secondary' >${(quantity * (price + extraPrice)).toFixed(2)}</Text>
		</div>
		<div className='cart-item-body' style={{ display: 'flex', justifyContent: 'space-between' }}>
		  <div className='cart-item-details'>
			{condiments}
			{extras}
			{modifyable ? (
			  <Button type='link' name='edit_button' style={{ marginLeft: 8 }} onClick={modifyModal}>
				{editLabel}
			  </Button>
			) : (
			  <Button disabled type='link' name='edit_button' style={{ marginLeft: 8 }} onClick={modifyModal}>
				{editLabel}
			  </Button>
			)}
		  </div>
		  <div style={{ minWidth: '25%' }}>
				<PlusMinus
					quantity={quantity}
					remove={removeFromOrder}
					add={addToOrder}
					padding='0px 8%'
				/>
		  </div>
		</div>
		<Divider />
	  </div>
	)
  }