
import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Form, Input, Icon, Button } from 'antd';
import { injectIntl } from "react-intl";
import TransitionSwitch from '../../components/TransitionSwitch.js';
import Activate from './AccessOptions/Activate.js';
import Reset from './AccessOptions/Reset.js';
import PasswordLess from './AccessOptions/PasswordLess.js';
import msg from './messages';
import * as CustomStorage from '../../utilities/customStorage';
import { isNativeStorageSupported } from '../../utilities/utils.js';

const FormItem = Form.Item;
const LEVEL = 3;
const USER_NAME_ITEM = 'USER_NAME_ACCESS';

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      fetched: false,
      passwordType: "password",
      screenOrientation: window.innerHeight +100 > window.innerWidth ? 'portrait' : 'landscape',
      theme: window.location.search.toLowerCase().includes('appname=joy') || // Check parameter for the android/ios app
            /joy\.(mirigi|local)\.com/.test(window.location.host) // Check if host matches joy domains
              ? 'joy' 
              : 'default',  
    };
    this.passwordRef = React.createRef();
  }

  componentDidMount = () => {
    this.recoverUserName();
    window.addEventListener('resize', this.updateDimensions); // so we can render component on resize
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value.trim() });
  }

  updateDimensions = () => {
    this.setState(prevState => ({
      screenOrientation: window.innerHeight +100 > window.innerWidth ? 'portrait' : 'landscape'
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const username = this.state.username;
    const password = this.state.password;
    this.props.loginFunction(username, password, this.props.history)
      .catch((e) => console.error("Could not login:", e))
      .finally(() => {
        this.setState({ fetched: true });
        this.storeUserName();
      });
  }

  showPassword = () => {
    console.log("show password");
    if (this.passwordRef.current){
      if (this.state.passwordType === "password") {
        this.passwordRef.current.type = "text";
        this.setState({passwordType: "text"});
      }else {
        this.passwordRef.current.type = "password";
        this.setState({passwordType: "password"});
      }
    }
}

  storeUserName = () => {
    if (isNativeStorageSupported()) {
      const username = this.state.username;
      CustomStorage.setItem(USER_NAME_ITEM, username);
    }
  }

  recoverUserName = () => {
    if (isNativeStorageSupported()) {
      CustomStorage.getItem(USER_NAME_ITEM).then(username => {
        if (username) {
          this.setState({ username });
        }
      });
    }
  }

  render = () => {
    const t = this.props.intl.formatMessage;
    return (
      <div className={`access ${this.state.theme}-theme`} >
          <div className={this.state.screenOrientation} >
            <div className="header">
              <div 
                className="logo-img"
              />
            </div>
            {/* separator for landscape */}
            {

              this.state.screenOrientation === 'landscape' && 
              <div className="separator" />
            }
            <div className="login-form-container">
            <Form onSubmit={this.handleSubmit} className={`login-form _${this.state.screenOrientation}`}> 
              <h1>{t(msg.login)}</h1>
              <FormItem>
                <Input
                  prefix={<Icon className='icon-color' type="user" />}
                  placeholder={t(msg.email)}
                  name="username"
                  type="email"
                  onChange={this.handleChange}
                  value={this.state.username}
                />
              </FormItem>
              <FormItem>
                {
                  this.state.passwordType === "password" ?
                    <Input
                      ref={this.passwordRef}
                      prefix={<Icon className='icon-color' type="lock" />}
                      suffix={
                          <Icon 
                            className='icon-color'
                            type="eye-invisible" 
                            onClick={() => this.showPassword()} id="passw-img" 
                          />
                      }
                      placeholder={t(msg.password)}
                      name="password"
                      type="password"
                      onChange={this.handleChange}
                    />
                    :
                    <Input
                      ref={this.passwordRef}
                      prefix={<Icon className='icon-color' type="lock" />}
                      suffix={
                        <Icon 
                          className='icon-color' 
                          type="eye" 
                          onClick={() => this.showPassword()} id="passw-img" 
                        />
                  }
                  placeholder={t(msg.password)}
                  name="password"
                  type="text"
                  onChange={this.handleChange}
                />
                }
              </FormItem>
              <Button type="primary" htmlType="submit">{t(msg.login)}</Button>
            </Form>
            </div>
          </div>
        <div className="footer">
          <Link className="footer-link" to={'reset/'}>{t(msg.forgotPassword)}</Link>
          <br />
          <Link className="footer-link" to={'activate/'}>{t(msg.newAccount)}</Link>
          <br />
          <Link className="footer-link" to={'device/'}>{t(msg.activateDevice)}</Link>
        </div>
        <div className="child-view">
            <TransitionSwitch level={LEVEL}>
              <Route path={'/access/reset'}    render={() => <Reset /> }/>
              <Route path={'/access/activate'} render={() => <Activate /> }/>
              <Route path={'/access/device'} render={() => <PasswordLess 
                login={this.props.loginFunction} 
                pLessLogin={this.props.pLessLogin} 
                history={this.props.history} />
              }/>
            </TransitionSwitch>
        </div>
      </div>
    );
  }
}

export default injectIntl(LogIn);
