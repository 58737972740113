import React, { Component } from 'react';

import { remote, reservations } from './helpers';
import { RESERVED_FOR_ME, RESERVED_FOR_NOBODY, RESERVED_FOR_ANOTHER, RESERVED_FOR_CLOSURE } from './helpers';

import { InputNumber, message } from 'antd';
import * as moment from 'moment';

import LoadingComponent from '../../../components/LoadingComponent';

import { injectIntl } from "react-intl";

import msg from './messages'
import MarkdownRenderer from '../../MDRenderer';
import debounce from 'lodash.debounce';

function hasMultipleItems() {
  return (this.props.item.amount !== 0);
}

class AvailabilityElement extends Component {
  constructor(props) {
    super(props);
  
    this.hasMultipleItems = hasMultipleItems.bind(this);
    this.onChangeMultiple = this.onChangeMultiple.bind(this);

    this.t = this.props.translator
    this.state = {
      selected: null,
    };
  }
  timeoutMultiple = null
  onChangeMultiple(value) {
    if (value == "") value = 0;
    var amount = value;
    this.setState({selected: value});
    if (value > this.props.availability.maximum || value+this.props.availability.foranother > this.props.availability.maximum ||  value < 0 || value==="" || value===null || !Number.isInteger(value)) {
      console.log('[ChooserCell:onChangeMultiple()]! Value is out of range. Sending "ERROR" signal to DatePicker');
      amount = 'ERROR';
    }
    console.log('[ChooserCell:onChangeMultiple()]! Value is in range. Sending "ERROR" signal to DatePicker');
    this.props.addRequestData(
      {
        type: 'item-chosen',
        item: {
          id: this.props.item.id,
          amount: amount,
        }
      }
    );
  }
  componentDidUpdate(prevProps) {
    if (this.props.availability !== prevProps.availability) {
      this.setState({selected: null});
    }
  }
  _renderSimple() {
    return (
      <LoadingComponent isLoading={this.props.isLoading}>
       <div style={{ whiteSpace: 'pre-line' }}>
        {
          this.props.availability.forme
          ? this.t(msg.reservedForYou) + ' ' + this.t(msg.modalCancel)
          : this.props.availability.foranother
          ? this.t(msg.reservedForAnother)
          : this.props.availability.closed
          ? this.t(msg.closed)
          : this.t(msg.available) + '\n' + this.t(msg.modalReserve)
        }
        </div>
      </LoadingComponent>
    );
  }
  _renderMultiple() {
    return (
      <div>
        <InputNumber 
          min={0}
          value={this.state.selected !== null ? this.state.selected:this.props.availability.forme}
          onChange={debounce(this.onChangeMultiple, 400)}
        />
        <span>
          ({this.props.availability.maximum-this.props.availability.foranother})
        </span>
      </div>
    );
  }
  render() {
    return (
      <div className="availability-element">
        {
          this.hasMultipleItems()
          ? this._renderMultiple()
          : this._renderSimple()
        }
      </div>
    );
  }
}

class ChooserCell extends Component {
  constructor(props) {
    super(props);

    this.hasMultipleItems = hasMultipleItems.bind(this);
    this.getReservationLevel = this.getReservationLevel.bind(this);
    this.isReservedForMe = this.isReservedForMe.bind(this);
    this.state = {};
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.availability != this.props.availability) {
      this.setState({
        isLoading: false
      });
    }
  }

  getReservationLevel() {
    var reservationLevel = RESERVED_FOR_NOBODY;

    if (this.props.availability.forme > 0) {
      reservationLevel = RESERVED_FOR_ME;
    } else if ((this.props.availability.maximum !== 0 && this.props.availability.foranother === this.props.availability.maximum) ||
    (this.props.availability.maximum == 0 && this.props.availability.foranother === 1)) {
      reservationLevel = RESERVED_FOR_ANOTHER;
    }else if (this.props.availability.closed){
      reservationLevel = RESERVED_FOR_CLOSURE;
    }

    return reservationLevel;
  }
  isReservedForMe() {
    return (
      this.getReservationLevel() === RESERVED_FOR_ME
    );
  }
  onClickCell() {
    var reservationLevel = this.getReservationLevel();
    console.log('Chooser cell clicked. Reservation level is '+reservationLevel);
    if (!this.state.isLoading && [RESERVED_FOR_NOBODY, RESERVED_FOR_ME].includes(reservationLevel)) {
      var eventData = {item: {}};
      eventData.type = 'date-chosen';
      eventData.item.id = this.props.item.id;
      console.log('[amenities:ChooserCell.onClickCell()] Item selected: '+eventData.item.id);
      if (this.isReservedForMe() && !this.hasMultipleItems()) {
        console.log('[amenities:ChooserCell.onClickCell()] Reservation cancelled');
        if(!remote.canCancelReservation(this.props.minTimeInAdvance, this.props.minAdvanceUnit, this.props.date.clone(), this.props.isByHour)){
          message.warning(this.props.intl.formatMessage(msg.cannotCancel));
          return
        }
        eventData.type = 'cancel';
      }
      this.props.addRequestData(eventData);
      this.setState({
        isLoading: true
      });
    }
  }
  render() {
    const t = this.props.intl.formatMessage
    let symbol_price = this.props.item.symbol_price

    if (this.props.item.symbol_price.includes("N/A")) symbol_price = t(msg.priceNotSpecified)
    else if (this.hasMultipleItems()){symbol_price = symbol_price + " " + t(msg.each)}

    return (
      <div
        className={'chooser-cell ' + (
          reservations.getStyledClass(this.getReservationLevel())
        )}
        onClick={
          this.hasMultipleItems()
          ? null
          : () => this.onClickCell()
        }
      >
        <div className="item-name">
          {this.props.item.name}
        </div>
        <div className="item-description">
          <MarkdownRenderer markdown={this.props.item.description}></MarkdownRenderer>
        </div>
        <div className="item-availability">
          <AvailabilityElement
            item={this.props.item}
            availability={this.props.availability}
            addRequestData={this.props.addRequestData}
            isLoading={this.state.isLoading}
            setLock={this.props.setLock}
            translator={this.props.intl.formatMessage}
            minTimeInAdvance={this.props.minTimeInAdvance}
          />
        </div>
        <div className="item-price">
          {symbol_price}
        </div>
      </div>
    );
  }
}

export default ChooserCell = injectIntl(ChooserCell)