import React from 'react';

import { MinusOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';


/**
 * A component for displaying a quantity value with buttons to increment and decrement it.
 *
 * The component shows a `-` button, the quantity value and a `+` button.
 * The user can click on the `-` button to decrement the quantity value, on the `+` button to increment it
 * or on the quantity value to execute the `numberClick` function.
 * The user can also click on the whole component to execute the `generalClick` function.
 *
 * The `-` button is replaced by a `🗑` button when the quantity value is 1.
 * When the user clicks on the `🗑` button, the `eliminate` function is executed.
 * If the `eliminate` function is not provided, the `remove` function is executed.
 * 
 * There is no internal state held in this component. If the quantity must change, it must be updated
 * by the parent component and its state kept outside of this component
 *
 * @param {number} quantity - The current quantity value.
 * @param {function} add - Function to execute when the user clicks on the `+` button.
 * @param {function} remove - Function to execute when the user clicks on the `-` button.
 * @param {function} [eliminate] - (optional) Function to execute when the user clicks on the `🗑` button.
 * @param {function} [numberClick] - (optional) Function to execute when the user clicks on the quantity value.
 * @param {object} [style] - (optional) The style object for the component.
 * @param {string} [padding='0'] - (optional) The padding style for the component.
 * @param {string} [width='100%'] - (optional) The width style for the component.
 * @param {function} [generalClick=() => {}] - (optional) Function to execute when the user clicks on the whole component.
 */
export const PlusMinus = ({ quantity, add, remove, eliminate, numberClick, padding='0', width='100%', style={}, generalClick=() => {} }) => {
	const executeFunction = (event, action) => {
		event.stopPropagation();
		switch (action) {
			case 'add':
				add ? add() : generalClick()
				break;
			case 'remove':
				remove ? remove() : generalClick()
				break;
			case 'eliminate':
				if (eliminate) {
					eliminate();
				} else {
					remove ? remove() : generalClick()
				}
				break;
			case 'numberClick':
				numberClick ? numberClick() : generalClick()
				break;
			default:
				generalClick()
				break;
		}
	}

	return (
		<div 
			className='plus-minus-container'
			style={{ 
				padding: padding,
				width: width,
				...style
			}}
			onClick={(e) => {
				executeFunction(e, 'generalClick')
			}}
		>
			{quantity > 1 ? (
				<MinusOutlined name='minus_button' className='hover-grow'  onClick={(e) => executeFunction(e, 'remove')} />
			) : (
				<DeleteOutlined name='minus_button' className='hover-grow' style={{ color: 'red' }} onClick={(e) => executeFunction(e, 'eliminate')} />
			)}
			<span name='quantity' className={numberClick ? 'hover-grow' : ''}  onClick={(e) => executeFunction(e, 'numberClick')}>
				{quantity}
			</span>
			<PlusOutlined name='plus_button' className='hover-grow'  onClick={(e) => executeFunction(e, 'add')} />
		</div>
	);
};