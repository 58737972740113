import React from 'react';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router-dom';
import { Button, Modal, Typography, List, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { ZoomableImage } from '../../../components/ZoomableImage'
import msg from './messages';
import { isEqual } from 'lodash';

import { CartItem } from './CartItem'

const { Title, Paragraph,Text } = Typography;

class DishVariantsModal extends React.Component {
    constructor(props) {
      super(props)
      this.state = {};
      this.translate = this.props.intl.formatMessage // Get translation function from props
    }
  
    removeFromOrder = (indexInOrder) => {
      const { removeFromOrder, order, exampleDish, closeModal } = this.props
      
      let dishes = order.map((dish, index) => ({...dish, indexInOrder: index})).filter((d) => d.id == exampleDish.id)

      if (dishes.length === 1 && dishes[0].quantity === 1) {
        closeModal()
      }
      removeFromOrder(indexInOrder)
    }
    render() {
      const { order, exampleDish, visible, defaultLang, currentLang, closeModal, addToOrder, modifyModal, translate, isMobile } = this.props;
      
      let dishes = order.map((dish, index) => ({...dish, indexInOrder: index})).filter((d) => d.id == exampleDish.id)

      const modifyable = (exampleDish.extras != null && exampleDish.extras.length > 0) || (exampleDish.option_groups != null && exampleDish.option_groups.length > 0)

      // FIXME: should be dynamic. Change when user created tags are implemented for menu staff
      // FIXME: add translation to the tag title?
      const possibleTags = {
        'veggie': { en: 'vegetarian', es: 'vegetariano'},
        'vegan': { en: 'vegan', es: 'vegano'},
        'gluten_free': { en: 'gluten free', es: 'sin gluten'}
      };
      
      return (
        <Modal
          transitionName='ant-modal-slide-up'
          className={'modal dish-variants-modal' + (isMobile ? ' mobile' : '')}
          title={
            <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem'}}>
              {exampleDish.image_url && (
                <ZoomableImage
                  src={exampleDish.image_url}
                  alt={exampleDish.name[currentLang] || exampleDish.name[defaultLang]}
                  width="5rem"
                  height="auto"
                  style={{ objectFit: 'cover', maxHeight: '100%', maxWidth: '100%', borderRadius: '5px' }}
                />
              )}
              <Title level={2} style={{margin: 0}}>
                {translate(msg.dishVariantsModalTitle, {dishName: exampleDish.name[currentLang] || exampleDish.name[defaultLang]})}
              </Title>
              <CloseOutlined onClick={() => {closeModal()}} />
            </div>
            {
              exampleDish.tags && (
                <div>
                  {exampleDish.tags.map((tag) => {
                    if (possibleTags[tag]) {
                      return (
                        <Tag color='green' style={{color: 'green'}}>{possibleTags[tag[currentLang]] || possibleTags[tag][defaultLang]}</Tag>
                      )
                    }
                    return null
                  })}
                </div>
              )
            }
            </>
          }
          zIndex={500}
          visible={visible}
          closable={false} // is false so we can add our own header with custom close action
          onCancel={() => {closeModal()}}
          onOk={() => {closeModal()}}

          // Should only have a button to add new variation
          footer={
            <div className='dish-variants-modal-footer'>
              <Button 
                name='add-variation-button'
                onClick={() => {modifyModal(-1, true, { ...exampleDish, selectedCondiments: {}, selectedExtras: [], category: exampleDish.category, quantity: 1 })}}
                disabled={!modifyable}
              >
                {translate(msg.addVariationButton)}
              </Button>
              <Button
                type={'primary'}
                name='close-variation-modal'
                onClick={() => closeModal()}
              >
                {translate(msg.confirm)}
              </Button>
            </div>
          }
        >
          <div>
            <List
              dataSource={dishes}
              renderItem={(dish) => {
                return (
                  <CartItem
                    name={dish.name}
                    price={dish.price}
                    quantity={dish.quantity}
                    selectedCondiments={dish.selectedCondiments}
                    selectedExtras={dish.selectedExtras}
                    currentLang={currentLang}
                    defaultLang={defaultLang}
                    removeFromOrder={() => this.removeFromOrder(dish.indexInOrder)}
                    addToOrder={() => addToOrder({ ...dish, quantity: 1 }, dish.category)}
                    modifyModal={() => modifyModal(dish.indexInOrder, false, dish)}
                    modifyable={modifyable}
                    editLabel={translate(msg.edit)}
                  />
                )
              }}
            />
          </div>
        </Modal>
      );
    }
}

export default injectIntl(withRouter(DishVariantsModal));
