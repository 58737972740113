import React from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'antd';
import { injectIntl } from "react-intl";
import msg from './messages'

class Header extends React.Component {
  render () {
    const t = this.props.intl.formatMessage
    let toggleMenu
    const logoURL = `//s3.amazonaws.com/static.mirigi.com/${this.props.building.name}/logo/resident-desktop-logo.png`
    if (this.props.tpMode) {
      toggleMenu = (
        <Link className="toggle-menu" to="settings/">
          <div className="toggle-menu-label">{this.props.showUnit}</div>
          <div className="toggle-menu-settings"> <Icon type="setting" /></div>
        </Link>
      ) 
    } else {
      toggleMenu = (
          <div className="toggle-menu" onClick={ this.props.toggleMenu}>
            {this.props.user_name? <h3>{t(msg.hello_user, { user_name: this.props.user_name})}</h3> : <div> </div>}
            <div className="toggle-menu-content">
              <div className="toggle-menu-label"> <Icon type="home" /> {this.props.showUnit}</div>
              <div className={"toggle-menu-button " + (this.props.hidden? 'hidden':'visible')}><Icon type="down" /></div>
            </div>
          </div>
      )
    }

    return (
      <div className='header'>
        <img src={logoURL} alt={this.props.building.name} />
        {toggleMenu}
      </div>
    )
  }
}

export default Header = injectIntl(Header)