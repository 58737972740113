import React from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'antd'
import InfoButton from './InfoButton'
import { withRouter } from "react-router"

class Header extends React.PureComponent {

  getBuilding = () => {
    let match = this.props.match
    let building = match.params.buildingName
    return building
  }

  render () {
    let img = ''
    let backButtonStyle = { }
    if (this.props.isGuest) {
      try {
        let imageStyle = {
          position: 'absolute',
          transform: 'translate(-50%)',
          'marginLeft': '50%'
        }
        img = <img style={imageStyle} height='45px' src={'//s3.amazonaws.com/static.mirigi.com/' + this.getBuilding() + '/logo/resident-desktop-logo.png'} alt={'building'}/>
      } catch(e) { }
      backButtonStyle = {
        visibility: 'hidden'
      }
    }

    return (
      <div className='page-header'>
        <div className='top'>
          <div style={backButtonStyle} className="back-link" onClick={this.props.history.goBack}><Icon className="icon" type="arrow-left" /></div>
          { 
            (this.props.pageInfo && this.props.pageInfo.content)
              ? <InfoButton title={this.props.pageInfo.title} content={this.props.pageInfo.content} />
              : null
          }
          { img }
        </div>
        <h1 className='title'>{this.props.children}</h1>
      </div>
    )
  }
}

export default Header = withRouter(Header)
