import React from 'react';
import { Button, Icon, Select, Spin, Tooltip } from 'antd';
import { injectIntl } from "react-intl";
import { Route, withRouter } from 'react-router-dom';
import msg from './messages';
import TransitionSwitch from '../../../components/TransitionSwitch';
import Transactions from './Transactions';
import Pay from './Pay';
import { SmartRequests } from '../../../utilities';
import TransactionInfo from './TransactionInfo';
import Box from '../../../components/Box';
import MessageAlert from '../../../components/MessageAlert';

const { Option } = Select;


class Expenses extends React.Component  {

    constructor(props) {
        super(props)
        this.state = {
            balance: 0,
            loading: true,
            pageError: false,
            selectedAccountingType: null,
            loadingAccounting: false,

            //Messages
            currentMsg: "",
        }
    }

    componentDidMount() {
        console.log('Mounting Expenses');
        this.t = this.props.intl.formatMessage;
        this.getPaymentsMethods();
    }

    getPaymentsMethods = () => {
        const t = this.props.intl.formatMessage;
        const accountingField = this.state.selectedAccountingType ? `?selected_accounting_role=${this.state.selectedAccountingType}` : "";
        const urlGet = "accounting" + accountingField;
        SmartRequests.get(urlGet).then( resp => {
            if (resp.status === 200) {
                if (resp.data.balance.amount !== null){
                    let currentMsg = this.getCurrentMsg(resp.data.balance, resp.data.currency_symbol);
                    this.setState({
                        balance: resp.data.balance,
                        currency_symbol: resp.data.currency_symbol,
                        currentMsg, loading: false,
                        userAccountingRole: resp.data.balance.accounting_role,
                        pageError: false,
                        loadingAccounting: false
                    });
                } else {  
                    this.setState({loading: false, 
                        userAccountingRole: resp.data.balance.accounting_role,
                        pageError: {status: resp.data.status, title: t(msg.loadDataNull)},
                        loadingAccounting: false
                    })
                }
            }
        }).catch( err => { 
            let title = t(msg.loadDataNull);

            if (err.response.data.status === "warning") {
                title = t(msg.noExpensesFoundUser);
            }

            this.setState({loading: false, pageError: {status: err.response.data.status, title: title}})
            console.log("Error: " + err);
        });
    }

    getCurrentMsg = (balance, currency_symbol) => {
        const t = this.props.intl.formatMessage;

        let currentMsg = t(msg.currentNegativeMsg, {currentBalance: balance.amount, nextDueDate: balance.end_date, currency_symbol});
        if (balance.amount < 0){
            currentMsg = t(msg.currentPositiveMsg, {currentBalance: Math.abs(balance.amount), currency_symbol});
        } else {
            if (balance.amount === 0){
                currentMsg = t(msg.currentNeutralMsg);
            } else {
                currentMsg = t(msg.currentNegativeMsg, {currentBalance: balance.amount, nextDueDate: balance.end_date, currency_symbol});
            }
        }
        return currentMsg;
    }

    viewTransactions = () => {
        const role = this.state.selectedAccountingType || this.state.userAccountingRole;
        this.props.history.push({
            pathname: `/${this.props.reqData.building.name}/${this.props.unit}/dashboard/expenses/transactions/${role}`,
        });
    }

    viewPayPage = () => {
        const role = this.state.selectedAccountingType || this.state.userAccountingRole;
        this.props.history.push({
            pathname: `/${this.props.reqData.building.name}/${this.props.unit}/dashboard/expenses/pay/${role}`,
        });
    }

    getTypeRolesMsg = () => {
        const t = this.t;
        const { selectedAccountingType, userAccountingRole } = this.state;

        return `${t(msg.accountType)}: ${selectedAccountingType || userAccountingRole}`;
    }

    handleChangeSelect = (value) => {
        this.setState({selectedAccountingType : value, loadingAccounting: true}, () => {
            this.getPaymentsMethods();
        });
    }

    showSelectOptions = () => {
        const { userAccountingRole, selectedAccountingType } = this.state;
        let options = ["inquilino"];

        if (!userAccountingRole) return null

        if (userAccountingRole === "propietario") {
            options.unshift("propietario");
        }

        const msgSelect = selectedAccountingType || userAccountingRole;

        return (
            <div className='select-type-account'>
                <h4>{`${this.t(msg.selectAccountToPay)}: `}</h4>
                <Select  
                    defaultValue={msgSelect}
                    onChange={this.handleChangeSelect}
                    disabled={options.length === 1}
                >
                    {options.map((rol, index) => {
                        return (
                            <Option key={index} value={rol}>{this.t(msg[rol])}</Option>
                        )
                    })}
                    
                </Select>
            </div>
        )
    }

    showPageError = (pageError) => {
        return (
            <>
                <div className='container-header-pay page-error'>
                    { this.showSelectOptions() }
                </div>
                <MessageAlert status={pageError.status} title={pageError.title}/>
            </>
        )
    }


  render() {
    const t = this.props.intl.formatMessage;
    const { balance, currentMsg, loading, pageError, currency_symbol } = this.state;

    let balanceExistsAndValid = balance && balance.amount !== null && balance.amount !== undefined;


    return (
        <div className='expenses'>
            <div className='container-body-expenses'>

                {loading && <Spin size="large"/>}

                {pageError && this.showPageError(pageError)}

                {!loading && !pageError &&
                    <Box className='container-content-expenses'>
                        <div className='container-header-pay'>
                            { this.showSelectOptions() }
                            <Button 
                                style={{backgroundColor: "transparent", borderColor: "rgba(0, 0, 0, 0.85)", color: "rgba(0, 0, 0, 0.85)"}} 
                                type='default' 
                                onClick={() => this.viewTransactions()}>{t(msg.viewMovements)}
                            </Button>

                            <div className='line'></div>
                        </div>

                        { this.state.loadingAccounting 
                            ?
                                <Spin size="large"/> 
                            :
                                <>
                                    <div className='container-current-balance'>
                                        <div className='current-balance'>
                                            <h2>{t(msg.currentBalance)}</h2>
                                            <Tooltip 
                                                placement="top" 
                                                title={currentMsg + `. ${this.getTypeRolesMsg(balance)}`} 
                                            >
                                                <div className='container-amount-help' >
                                                    {balanceExistsAndValid && <p>{`${currency_symbol} ${balance.amount.toFixed(2)}`}</p>}
                                                    <Icon type="question-circle" style={{ fontSize: '20px', color: 'rgb(0 126 255)' }} />
                                                </div>
                                            </Tooltip>
                                            
                                        </div>
                                        
                                        <div className='next-due-date'>
                                            <p>{balance && `${t(msg.nextDueDate)}: ${balance.end_date}`}</p>
                                        </div>
                                    </div> 
                                    <div className='container-pay-button'>
                                        <Button className='pay-button' type="primary" onClick={() => this.viewPayPage()}>{t(msg.pay)}</Button>
                                    </div>
                                </>
                        }                        
                        
                    </Box>
                }
            </div> 

            <TransitionSwitch level={this.props.level + 1}>
                <Route 
                    path={`/:buildingName/:unit/dashboard/expenses/transactions/:role`} 
                    render={() => <Transactions level={this.props.level + 2} reqData={this.props.reqData} unit={this.props.unit} userRole={this.state.userAccountingRole}/>}/>
                <Route 
                    path={`/:buildingName/:unit/dashboard/expenses/transaction-info/:id`} 
                    render={(props) => <TransactionInfo id={props.match.params.id} reqData={this.props.reqData} unit={this.props.unit} newMqttMessage={this.props.newMqttMessage} pageMessageReceivedCallback={this.props.pageMessageReceivedCallback} mqttTopicMessage={this.props.mqttTopicMessage}/>}/>
                <Route 
                    path={`/:buildingName/:unit/dashboard/expenses/pay/:role`} 
                    render={() => <Pay level={this.props.level + 2} currentBalance={balance.amount} nextDueDate={balance.end_date} reqData={this.props.reqData} unit={this.props.unit} userRole={this.state.userAccountingRole}/>}/>
            </TransitionSwitch>
        </div>  
    );
  }
}

export default injectIntl(withRouter(Expenses));


